<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app>
    <v-content>
      <v-card>
        <v-toolbar color="primary" dark dense fixed>
          <v-btn icon to="/delivery">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{$t('views.delivery.delivery_detail.confirm_receipt')}}</v-toolbar-title>
        </v-toolbar>
        <v-list class="mt50" two-line>
          <v-list-tile v-for="article in deliveryArticles" :key="article.itemNr">
            <v-list-tile-content>
              <v-list-tile-title>
                {{ article.itemNr }}
                <span :class="{
                        'red--text': article.status === 'notInPackage',
                        'orange--text': article.status === 'wrongArticle',
                        'green--text': article.status === 'booked'}"
                      class="ml10 fs14">
                  ({{ getItemStatusReadable(article) }})
                </span>
              </v-list-tile-title>
              <v-list-tile-sub-title>
                <span class="textBlack">{{ article.description }}</span>
                <span v-if="article.amount > 1" class="gray ml10 fs14">{{$tc('views.delivery.delivery_detail.n_items', article.amount, {n: article.amount})}}</span>
                <span v-if="article.amount == 1" class="gray ml10 fs14">SN: {{ article.sn }}</span>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action v-if="article.status !== 'wrongArticle'">
              <v-btn icon ripple @click="openDialog(article.itemNr)">
                <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
        <v-alert :value="!bin" type="warning">
          {{$t('views.delivery.delivery_detail.no_warehouse_or_storage_locations_available_to_book')}}
        </v-alert>
        <div v-if="bin" style="padding: 0 10px 10px 10px; margin-bottom: 30px;">
          <v-btn block color="primary" @click="modalWrongArticle = true" class="noTransform" dark>
            {{$t('views.delivery.delivery_detail.record_wrongly_delivered_item')}}
          </v-btn>
        </div>
        <v-dialog ref="dialog" v-model="modal" persistent lazy full-width width="100%">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
              {{$t('views.delivery.delivery_detail.confirm_item_receipt')}}
            </v-card-title>
            <v-card-text>
              <v-btn block color="success" class="mt-3 mb-4 noTransform" @click="updateArticleBooked(currentItemNr)">
                {{$t('views.delivery.delivery_detail.item_delivered_correctly')}}
              </v-btn>
              <v-btn block color="error" class="mb-3 mt-4 noTransform" @click="updateArticleNotInPackage(currentItemNr)">
                {{$t('views.delivery.delivery_detail.item_not_in_the_package')}}
              </v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modal = false"> Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog ref="dialogWrongArticle" v-model="modalWrongArticle" persistent lazy full-width width="100%">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
              {{$t('views.delivery.delivery_detail.record_wrong_item')}}
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                  v-model="templateId"
                  :items="project.templates"
                  item-value="id"
                  item-text="description"
                  :label="$t('views.delivery.delivery_detail.type')"
                  persistent-hint
              ></v-autocomplete>

              <div v-if="templateId">
                <v-text-field
                    v-if="template.sn"
                    v-model="sn"
                    :label="$t('views.delivery.delivery_detail.serial_number')"
                ></v-text-field>
                <v-text-field
                    v-else
                    v-model="count"
                    type="number"
                    :label="$t('views.delivery.delivery_detail.count')"
                ></v-text-field>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modalWrongArticle = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="modalWrongArticle = false; addWrongArticle()">
                {{$t('views.delivery.delivery_detail.add')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryDetail',
  components: {},
  props: {},
  data() {
    return {
      modal: false,
      modalWrongArticle: false,
      currentItemNr: undefined,
      templateId: undefined,
      sn: '',
      count: 1,
    }
  },
  computed: {
    ...mapGetters(['deliveryArticles', 'bin', 'projects', 'projectId']),
    project() {
      if (!this.projectId) return undefined;
      return this.projects.find(project => project.id === this.projectId)
    },
    template() {
      if (!this.templateId) return undefined;
      return this.project.templates.find(
        template => template.id === this.templateId
      )
    },
  },
  mounted() {
    console.log("call getDeliveryArticles", this.$route.params.orderId, this.$route.params.deliveryNr);
    this.$store.dispatch('getDeliveryArticles', {
      orderId: this.$route.params.orderId,
      deliveryNr: this.$route.params.deliveryNr
    });
    this.$store.dispatch('getProjects').then(() => {
      if (this.projects.length > 0) {
        this.projectId = this.projects[0].id
      } else {
        this.project = undefined
      }
    });
  },
  watch: {},
  methods: {
    openDialog(itemNr) {
      this.currentItemNr = itemNr;
      this.modal = true;
    },
    getItemStatusReadable(item) {
      if (item.status === "booked") return this.$t('views.delivery.delivery_detail.correctly_delivered');
      else if (item.status === "notInPackage") return this.$t('views.delivery.delivery_detail.not_in_the_package');
      else if (item.status === "wrongArticle") return this.$t('views.delivery.delivery_detail.wrongly_delivered');
      return this.$t('views.delivery.delivery_detail.please_book')
    },
    updateArticle(itemNr, status) {
      console.log('updateArticle', itemNr);
      this.$store.dispatch('updateArticleStatus', {
        orderId: this.$route.params.orderId,
        deliveryNr: this.$route.params.deliveryNr,
        itemNr: itemNr,
        status: status
      });
      this.modal = false
    },
    updateArticleBooked(itemNr) {
      this.updateArticle(itemNr, "booked")
    },
    updateArticleNotInPackage(itemNr) {
      this.updateArticle(itemNr, "notInPackage")
    },
    addWrongArticle() {
      console.log('addWrongArticle');
      this.$store.dispatch('addWrongArticle', {
        orderId: this.$route.params.orderId,
        deliveryNr: this.$route.params.deliveryNr,
        templateId: this.templateId,
        sn: this.sn,
        count: this.count
      });
      this.modal = false
    },
  },
}
</script>

<style scoped lang="scss">
.noTransform {
  text-transform: none
}
</style>
