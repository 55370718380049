<template>
  <v-app>
    <appBar />
    <v-content>
      <slot></slot>
    </v-content>
    <v-footer app>
      <bottomNav />
    </v-footer>
  </v-app>
</template>

<script>
import bottomNav from '../components/bottomNav'
import appBar from '../components/appBar'

export default {
  name: 'BasicLayout',
  components: {
    bottomNav,
    appBar,
  },
}
</script>
