<template>
  <basicLayout>
    <v-card>
      <v-list subheader>
        <div v-for="warehouse in warehouses" :key="warehouse.id">
          <v-subheader style="background-color: antiquewhite">
            {{ warehouse.label }}
          </v-subheader>
          <v-list-tile
            v-for="bin in warehouse.bins"
            :key="bin.id"
            :to="`/materials/${warehouse.id}/${bin.id}`"
          >
            <v-list-tile-content>
              <v-list-tile-title>
                {{ bin.label }}
                <span style="font-size: 70%; color: #999; float: right"
                  >{{$tc('views.material.materials.n_items', bin.countArticles, {n: bin.countArticles})}}</span
                >
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </div>
      </v-list>
      <div style="padding: 0 10px 10px 10px; margin-bottom: 30px;">
        <v-btn block color="primary" to="/materials/new" dark>
          {{$t('views.material.materials.incoming_goods')}}
          <v-icon color="white">keyboard_arrow_right</v-icon>
        </v-btn>
      </div>
      <div style="padding: 0 10px 10px 10px; margin-bottom: 30px;">
        <v-btn block color="primary" to="/materials/export" dark>
          {{$t('views.material.materials.goods_issue')}}
          <v-icon color="white">keyboard_arrow_right</v-icon>
        </v-btn>
      </div>
    </v-card>
  </basicLayout>
</template>

<script>
import basicLayout from '@/layouts/basicLayout'

export default {
  components: {
    basicLayout,
  },
  data() {
    return {}
  },
  computed: {
    warehouses() {
      return this.$store.getters.warehouses
    },
  },
  created: function() {
    this.$store.dispatch('getWarehouses').then(() => {})
  },
  mounted() {},
  methods: {},
}
</script>
