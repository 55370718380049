<template>
  <v-card v-if="currentSignature" class="signature-overlay">
    <h1>{{ currentSignature.heading }}</h1>
    <p>{{ currentSignature.description }}</p>
    <vueSignature
      class="canvas"
      ref="signature"
      :w="'300px'"
      :h="'150px'"
    ></vueSignature>
    <p class="subline">
      {{$t('views.order.signatures.sign_here')}}
    </p>

    <h3>{{$t('views.order.signatures.signing_persons_name')}}</h3>
    <input :value="currentSignature.name || ''" @input="updateName" />
    <br />
    <v-btn flat @click="clear">{{$t('views.order.signatures.reset')}}</v-btn>

    <v-divider />
    <v-card-actions>
      <v-btn flat color="primary" @click="close">
        Cancel
      </v-btn>
      <v-spacer />
      <v-btn @click="save">{{$t('views.order.signatures.save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import vueSignature from 'vue-signature'

export default {
  components: {
    vueSignature,
  },
  props: ['close'],
  data() {
    return {}
  },
  computed: {
    currentSignature() {
      return this.$store.state.devices.signatures.filter(
        sig => !sig.done && !sig.queued
      )[0]
    },

    allSignaturesDone() {
      const areAllSignaturesDone = this.$store.state.devices.signatures.reduce(
        (acc, sig) => {
          return sig.done && acc
        },
        true
      )
      return areAllSignaturesDone
    },
  },
  watch: {},
  mounted() {},
  methods: {
    save() {
      const svg = this.$refs.signature.save('image/svg+xml')
      this.$store.dispatch('saveSignature', {
        ...this.currentSignature,
        signature: svg,
      })
      this.clear()
    },
    clear() {
      this.$refs.signature.clear()
    },
    updateName(e) {
      this.$store.commit('updateSignature', {
        ...this.currentSignature,
        newValue: { name: e.target.value },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 4px;
}

.canvas {
  border: 1px solid black;
  margin: auto;
}

.signature-overlay {
  padding: 5px;
}

.subline {
  margin-bottom: 10px;
  font-size: 12px;
  opacity: 50%;
}
</style>
