
<template>
  <div>
    <v-btn
      v-if="position && position.coords && showSuccessAlert"
      style="padding: 0; border: 0; background-color: black;">
      <v-alert
        value="true"
        type="success"
        icon="location_on"
        color="#53cdc4"
        outline
        style="padding: 4px 12px;"
      >
        {{$t('components.geolocation_checker.gps_active')}}
      </v-alert>
    </v-btn>
    <v-dialog
      v-if="!position || !position.coords"
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          @click="askForGeolocation(true, true)"
          v-on="on"
          style="padding: 0; border: 0; background-color: black;">
            <v-alert
              value="true"
              type="warning"
              icon="location_off"
              :color="'rgb(255 121 0)'"
              outline
              style="padding: 4px 12px;"
            >
              {{$t('components.geolocation_checker.no_gps')}}
            </v-alert>
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{$t('components.geolocation_checker.enable_location')}}
        </v-card-title>

        <v-card-text>
          {{ dialogText }}
        </v-card-text>

        <v-card-text v-if="additionalBrowserDialogText">
          {{ additionalBrowserDialogText }}
        </v-card-text>

        <v-card-text v-if="additionalWebAppDialogText">
          {{ additionalWebAppDialogText }} <v-icon>location_off</v-icon>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            flat
            @click="askForGeolocation(true, true)"
          >
            {{$t('components.geolocation_checker.refresh')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat
            @click="closeDialog()"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        position: null,
        dialog: false,
        dialogText: this.$t('components.geolocation_checker.please_enable_your_location'),
        additionalBrowserDialogText: '',
        additionalWebAppDialogText: '',
        showSuccessAlert: false
      }
    },
    methods: {
      askForGeolocation(show_error, show_success_alert) {
        console.log('trying to get geolocation...')
        let success_function = show_success_alert ? this.savePositionAndShowSuccessAlert : this.savePosition
        if (navigator.geolocation) {
          if (show_error) {
            navigator.geolocation.getCurrentPosition(success_function, this.showError);
          } else {
            navigator.geolocation.getCurrentPosition(success_function);
          }
        } else {
          this.dialogText = this.$t('components.geolocation_checker.browser_does_not_support_location_sharing')
        }
      },
      savePosition(position) {
        console.log('got geolocation!')
        this.position = position
      },
      savePositionAndShowSuccessAlert(position) {
        this.savePosition(position)
        this.showSuccessAlert = true
      },
      showError(error) {
        this.additionalBrowserDialogText = ''
        this.additionalWebAppDialogText = ''
        switch(error.code) {
          case error.PERMISSION_DENIED:
            this.dialogText = this.$t('components.geolocation_checker.please_enable_your_location_for_app')
            this.additionalBrowserDialogText = this.$t('components.geolocation_checker.additional_browser_info')
            this.additionalWebAppDialogText = this.$t('components.geolocation_checker.additional_web_app_info')
            break;
          case error.POSITION_UNAVAILABLE:
            this.dialogText = this.$t('components.geolocation_checker.position_unavailable')
            break;
          case error.TIMEOUT:
            this.dialogText = this.$t('components.geolocation_checker.timeout')
            break;
          case error.UNKNOWN_ERROR:
            this.dialogText = this.$t('components.geolocation_checker.unknown_error')
            break;
        }
      },
      closeDialog() {
        this.dialog = false
        this.askForGeolocation(false, true)
      }
    },
    mounted() {
      this.askForGeolocation(false, false)
    }
  }
</script>
