<template>
  <v-app v-if="device" class="job-list">
    <template v-if="device">
      <v-toolbar color="primary" dark dense fixed>
        <v-btn icon @click.native="backToDevices">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{$t('views.devices.job_list.jobs_for_indetifier', {identifier: device.identifier})}}</v-toolbar-title>
      </v-toolbar>
      <div class="job-list-content">
        <v-list class="nopadding">
          <Job
            v-for="job in openJobs"
            v-bind:key="job.id"
            :job="job"
            :openOverlay="openOverlay.bind(this)"
            :answerBool="answerBool.bind(this)"
            :openPhotoDocumentation="openPhotoDocumentation.bind(this)"
          />
        </v-list>

        <h2>{{$t('views.devices.job_list.already_done')}}</h2>
        <v-list class="nopadding" style="opacity: 50%;">
          <Job
            v-for="job in doneJobs"
            v-bind:key="job.id"
            :job="job"
            :openOverlay="openOverlay.bind(this)"
            :answerBool="answerBool.bind(this)"
            :openPhotoDocumentation="openPhotoDocumentation.bind(this)"
          />
        </v-list>
      </div>
      <v-dialog v-model="dialog" full-width>
        <v-card style="padding: 1.4rem">
          <v-card-title class="headline lighten-2" primary-title>
            {{$t('views.devices.job_list.reason')}}
          </v-card-title>

          <v-card-text>
            <v-textarea
              v-model="reason"
              name="input-7-1"
              :label="$t('views.devices.job_list.pleasy_write_a_short_explanation')"
              rows="6"
              no-resize
              :error="!checkReason()"
              counter="true"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn flat color="primary" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              flat
              color="primary"
              @click="answerBool(currentId, currentSelection)"
              :disabled="!checkReason()"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <div v-else>Load...</div>
  </v-app>
</template>

<script>
import { assureDeviceData } from '../../store/modules/devices'
import Job from './Job'

export default {
  components: {
    Job,
  },
  data() {
    return {
      deviceList: [],
      dialog: false,
      reason: '',
      currentId: null,
      currentSelection: false,
    }
  },
  computed: {
    orderId() {
      return this.$route.params.orderId
    },
    deviceId() {
      return this.$route.params.deviceId
    },
    device() {
      return this.$store.getters.getDevice(this.deviceId)
    },
    doneJobs() {
      return (
        this.device.jobs.filter(job => (!job.done && job.queued) || job.done) ||
        []
      )
    },
    openJobs() {
      return (
        this.device.jobs.filter(
          job => !((!job.done && job.queued) || job.done)
        ) || []
      )
    },
  },
  watch: {},
  mounted() {
    assureDeviceData(this.$store, this.$router, this.orderId)
  },
  methods: {
    openPhotoDocumentation(jobId) {
      this.$router.push(
        `/orders/${this.orderId}/devices/${this.deviceId}/photo/${jobId}`
      )
    },
    openOverlay(job, currentSelection) {
      this.dialog = true
      this.reason = job.reason || ''
      this.currentId = job.id
      this.currentSelection = currentSelection
    },
    answerBool(jobId, isJobDone) {
      this.$store
        .dispatch('saveBooleanJob', {
          isJobDone,
          orderId: this.orderId,
          deviceId: this.deviceId,
          jobId,
          reason: this.reason,
        })
        .then(() => {
          this.dialog = false
          this.reason = ''
          this.currentId = null
        })
        .catch(e => {
          if (e.message === 'Request failed with status code 401') {
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/login')
            })
          } else {
            console.error('FIXME_PHOTO_DOCUMENTATION')
          }
        })
    },
    backToDevices() {
      this.$router.push(`/orders/${this.orderId}`)
    },
    checkReason() {
      return /\S/.test(this.reason)
    },
  },
}
</script>

<style lang="scss">
.card-heading {
  padding: 20px 10px;
}

.job-list .application--wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .v-toolbar--fixed {
    position: relative;
  }

  .job-list-content {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;
  }
}

.checklist-element {
  border: 1px solid #eee;
  margin: 10px 5px;
  border-radius: 5px;
  padding: 5px;
  position: relative;
}

.checklist-control {
  text-align: center;
}

.success-indicator {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  background: #1976d2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.success-indicator.progress {
  background: #ffb100;
}
</style>
