/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
import { config } from '@/config'
import sync from '@/sync/sync'

function getDevice_(devices, deviceId) {
  return devices.reduce(
    (target, device) => (device.id.toString() === deviceId ? device : target),
    null
  )
}

function getJob_(device, jobId) {
  return device
    ? device.jobs.reduce(
        (target, job) => (job.id.toString() === jobId ? job : target),
        null
      )
    : null
}

const state = {
  list: [],
  activeOrder: null,
  queuedUploads: 0,
  signatures: [],
  deviceLoadingState: 'NOT_ASKED',
  selectedCamera: null,
}

const mutations = {
  setOperationDevices(state, { orderId, devices, deviceLoadingState }) {
    if (orderId && devices) {
      state.list = devices
      state.activeOrder = orderId
      state.deviceLoadingState = deviceLoadingState
    }
  },
  setOperationSignatures(state, { orderId, signatures }) {
    if (orderId && signatures) {
      state.signatures = signatures
      state.activeOrder = orderId
    }
  },
  updateJob(state, { deviceId, jobId, newValue }) {
    if (deviceId && jobId) {
      state.list = [
        ...state.list.map(device => ({
          ...device,
          jobs: device.jobs.map(job => {
            return job.id.toString() === jobId.toString()
              ? { ...job, ...newValue }
              : job
          }),
        })),
      ]
    }
  },

  updateSignature(state, { ticket_id, signature_id, newValue }) {
    if (ticket_id && signature_id) {
      state.signatures = state.signatures.map(sig => {
        return sig.ticket_id.toString() === ticket_id.toString() &&
          sig.signature_id.toString() === signature_id.toString()
          ? { ...sig, ...newValue }
          : sig
      })
    }
  },

  updateQueuedUploads(state, count) {
    state.queuedUploads = count
  },

  selectCamera(state, deviceId) {
    state.selectedCamera = deviceId
  },
}

const actions = {
  getDevicesForOrder({ rootState, commit, dispatch, state }, id) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('railsToken')

      const url = `${config.RAILS_API}/api/v1/app/operations/${id}/jobs.json`
      const options = {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
        url,
      }

      commit('setOperationDevices', {
        devices: [],
        orderId: id,
        deviceLoadingState: 'LOADING',
      })

      axios(options)
        .then(res => {
          const { devices } = res.data

          commit('setOperationDevices', {
            orderId: id,
            deviceLoadingState: 'SUCCESS',
            devices: devices,
          })
          resolve(res)
        })
        .catch(err => {
          commit('setOperationDevices', {
            orderId: id,
            deviceLoadingState: 'FAILURE',
            devices: [],
          })
          reject(err)
        })
    })
  },

  getSignaturesForOrder({ commit }, id) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('railsToken')

      const url = `${config.RAILS_API}/api/v1/app/operations/${id}/signature_jobs.json`
      const options = {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
        url,
      }

      // commit('setOperationSignatures', {
      //   orderId: id,
      //   signatures: [
      //     {
      //       ticket_id: 123,
      //       signature_id: 4,
      //       done: false,
      //       heading: 'Techniker',
      //       description:
      //         'Mit der Unterschrift werden die ausgeführten Arbeiten, das verbaute Material und die Arbeitszeiten bestätigt. Die Arbeiten wurden ordentlich ausgeführt und der Einsatzbereich wurde in einem einwandfreien Zustand hinterlassen.',
      //       name: 'Tim Techniker',
      //     },
      //   ],
      // })

      axios(options)
        .then(res => {
          commit('setOperationSignatures', {
            orderId: id,
            signatures: res.data,
          })
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  saveSignature({ commit }, { ticket_id, signature_id, signature, name }) {
    return new Promise((resolve, reject) => {
      const signed_at = new Date()

      sync
        .addSignature({
          id: `${signature_id}_${ticket_id}`,
          ticket_id,
          signature_id,
          signed_at,
          signature,
          name,
        })
        .then(() => {
          commit('updateSignature', {
            signature_id,
            ticket_id,
            newValue: {
              done: false,
              queued: true,
            },
          })
          resolve()
        })
        .catch(err => {
          console.error('Saving a signature FAILED - COULD_NOT_ADD_TO_IDB', err)
          reject()
        })
    })
  },

  saveImage(
    { rootState, commit, dispatch, state },
    { orderId, deviceId, jobId, originalImg, overlayedImg }
  ) {
    return new Promise((resolve, reject) => {
      const done_at = new Date()

      //
      // It should NEVER come to this, but if either of the two
      // images are empty, we reject this promise altogether to
      // prevent sending a faulty request.
      //
      if (!originalImg || !overlayedImg) {
        reject()
        return
      }

      sync
        .addJob({
          id: jobId,
          done_at,
          result_image: originalImg,
          result_overlay_image: overlayedImg,
          job_type: 'picture',
          deviceId,
        })
        .then(() => {
          commit('updateJob', {
            deviceId,
            jobId,
            newValue: {
              done: false,
              queued: true,
            },
          })
          resolve()
        })
        .catch(err => {
          console.error('SAVING IMAGE FAILED - COULD_NOT_ADD_TO_IDB', err)
          reject()
        })
    })
  },

  saveBooleanJob(
    { rootState, commit, dispatch, state },
    { orderId, deviceId, jobId, isJobDone, reason }
  ) {
    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/jobs/answer`
      const token = localStorage.getItem('railsToken')

      const options = {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        data: qs.stringify({
          id: jobId,
          done_at: new Date(),
          value: isJobDone,
          reason,
        }),
        url,
      }

      axios(options)
        .then(res => {
          commit('updateJob', {
            deviceId,
            jobId,
            newValue: {
              done: true,
              value: isJobDone ? 'true' : 'false',
              reason,
            },
          })
          resolve(res)
        })
        .catch(err => {
          console.error('COULD_NOT_WRITE_BOOLEAN_JOB', err)
          reject(err)
        })
    })
  },
}

const getters = {
  getDevice: state => deviceId => getDevice_(state.list, deviceId),
  getJob: state => (deviceId, jobId) =>
    getJob_(getDevice_(state.list, deviceId), jobId),
}

//
// Put here because it is needed in multiple components
//
export function assureDeviceData(store, router, orderId) {
  const list = store.state.devices.list
  const activeOrder = store.state.devices.activeOrder
  if (!list || !list.length || !activeOrder || activeOrder !== orderId) {
    store
      .dispatch('getDevicesForOrder', orderId)
      .then(_ => {
        // TODO: maybe refactor this to be less spaghetti-esque
        store.dispatch('getSignaturesForOrder', orderId).then(() => {
          // We need to watch for currently queued elements to show the correct
          // status to finish the order
          sync.getCurrentQueueValues()
        })
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 401') {
          alert('Ihre Sitzung ist abgelaufen.')
          store.dispatch('logout').then(() => {
            router.push('/login')
          })
        } else {
          console.error('FIXME_PHOTO_DOCUMENTATION', err)
        }
      })
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}
