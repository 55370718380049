/**
 * This is the outermost API that gets exposed to the JavaScript application.
 *
 * It aims to provide a complete API surface for the frontend. This means the
 * user shall be able to invoke `SyncEngine.public_api_method(PARAMS)` for
 * everytime they want to interface with the tool.
 *
 * Functions in this module have to make METICULOUSLY sure that the inputs are
 * valid! This should be deferred to Elm, whenever possible, since it is
 * enforced by the compiler in Elm land.
 */

import { SendMsgToElm } from './signatures'
import { FromElm } from './messages_from_elm'
import { DatabaseResponseMsg } from './messages_to_elm'
import { handleDatabaseQuery } from './db'
import { registerEvents } from './events'

let send: SendMsgToElm
let modelChangedHandler = (event: any) => {
  return event
}

function handleElmMsg(fromElm: FromElm, send: SendMsgToElm) {
  switch (fromElm.msg) {
    case 'DatabaseRequestMsg':
      handleDatabaseQuery(fromElm.data, (dbEvent: DatabaseResponseMsg) =>
        send({
          msg: 'DatabaseResponseMsg',
          data: dbEvent,
        })
      )
      break

    case 'LogoutMsg':
      if (window.logoutHook && typeof window.logoutHook === 'function') {
        window.logoutHook()
      }
      break

    case 'NextModelMsg':
      modelChangedHandler(fromElm.data)

      //
      // We do this, because every time we change the model, we want this to
      // be saved to disk. This makes the interface on the Elm side
      // significantly easier to work with.
      //
      handleDatabaseQuery(
        {
          msg: 'PutModel',
          data: { id: 'model', model: fromElm.data },
        },
        (dbEvent: DatabaseResponseMsg) =>
          send({
            msg: 'DatabaseResponseMsg',
            data: dbEvent,
          })
      )
      break

    case 'DecodeError':
      console.warn(fromElm.data)
      break

    default:
      console.warn('Unknown msg', fromElm)
      break
  }
}

export { getModel } from './db'

export function setup(ports: any) {
  //
  // Make sure all ports are present and callable to avoid a runtime error.
  //
  if (
    ports &&
    ports.in_ &&
    ports.out_ &&
    ports.in_.send &&
    ports.out_.subscribe &&
    typeof ports.in_.send === 'function' &&
    typeof ports.out_.subscribe === 'function'
  ) {
    send = ports.in_.send

    registerEvents(send)

    ports.out_.subscribe((fromElm: FromElm) => handleElmMsg(fromElm, send))
  } else {
    //
    // If we find ourselves in this situation, the application MUST crash
    // since we cannot gurantee that the API is at all satisfied
    //
    throw new Error(
      'Could not initialize the sync engine, due to missing ports'
    )
  }
}

//
//
// PUBLIC API BELOW
//
//

/**
 * Instructs the engine to make a login request
 */
export function login(email: string, password: string) {
  send({
    msg: 'UiMsg',
    data: {
      msg: 'UiLogin',
      data: {
        email,
        password,
      },
    },
  })
}

/**
 * This function will attempt to fetch data for the given operation ID.
 */
export function loadOperationData(operationId: number) {
  send({
    msg: 'UiMsg',
    data: {
      msg: 'UiRequestSignaturesForOperation',
      data: operationId,
    },
  })

  send({
    msg: 'UiMsg',
    data: {
      msg: 'UiRequestDevicesForOperation',
      data: operationId,
    },
  })
}

/**
 * This function allows the client to subscribe to any change of the data model.
 * It is the responsibility of the caller to make sure only valid fields are
 * accessed later on.
 */
export function subscribeToModel(cb: (model: any) => {}) {
  modelChangedHandler = (e: any) => {
    cb(e)
  }
}

/**
 * This function allows the client to add a base64 encoded image for a specific
 * job and device to the sync engine.
 */
export function addJobImages(
  operationId: number,
  deviceId: number,
  jobId: number,
  image: string,
  imageWithOverlay: string
) {
  send({
    msg: 'UiMsg',
    data: {
      msg: 'UiAddJobImages',
      data: {
        operationId,
        deviceId,
        jobId,
        image,
        imageWithOverlay,
      },
    },
  })
}

/**
 * This function allows the client to add a base64 encoded svg for a specific
 * operation to the base engine.
 */
export function addSignature(
  operationId: number,
  signatureId: number,
  image: string
) {
  send({
    msg: 'UiMsg',
    data: {
      msg: 'UiAddSignature',
      data: {
        operationId,
        signatureId,
        image,
      },
    },
  })
}

/**
 * This function allows the client to finish a boolean job (with and optional
 * reason)
 */
export function saveBooleanJob(
  operationId: number,
  deviceId: number,
  jobId: number,
  value: boolean,
  reason: string = ''
) {
  send({
    msg: 'UiMsg',
    data: {
      msg: 'UiAddJobBool',
      data: {
        operationId,
        deviceId,
        jobId,
        value,
        reason,
      },
    },
  })
}
