<template>
  <div>
    <router-view />
    <div v-if="showOverlay" class="toast">
      <span @click="goToOperations">{{ $t('app.reload_operations') }}</span>
      <span @click="closeToast" class="close">x</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      showOverlay: false,
    }
  },
  created: function() {
    this.$http.interceptors.response.use(undefined, function(err) {
      return new Promise(function() {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err
      })
    })
  },
  mounted: function() {
    // We need to use a global reference here to prevent
    // adding the same interval more than once.
    // Otherwise it would spam the user and cost battery...
    clearInterval(window.interval)
    window.interval = setInterval(() => {
      const token = this.$store.state.token
      const timestamp = new Date().getTime()
      const lastOperationFetch = localStorage.getItem('lastOperationFetch')

      if (token && lastOperationFetch) {
        // *-1 because we don't want to compare against a negative value
        const secondsSinceCheckout =
          ((lastOperationFetch - timestamp) / 1000) * -1

        //
        // 2 hours after the last checkout, we show a hint.
        // We do this because technicians might checkout the operations the
        // evening before and the operation details might change shortly before
        // the actual operation takes place.
        // So this covers the situation that the technician claims "they didn't
        // know"
        //
        if (secondsSinceCheckout >= 7200) {
          this.showOverlay = true
        } else {
          this.showOverlay = false
        }
      }
      // Every minute
    }, 60000)
  },
  methods: {
    closeToast() {
      this.showOverlay = false
    },
    goToOperations() {
      this.showOverlay = false
      this.$router.push('/Orders')
    },
  },
}
</script>

<style lang="scss">
$sizes: (0, 2, 4, 6, 8, 10, 15, 20, 25, 30, 50);
@each $size in $sizes {
  .mt#{$size} {
    margin-top: $size + px;
  }
  .mr#{$size} {
    margin-right: $size + px;
  }
  .mb#{$size} {
    margin-bottom: $size + px;
  }
  .ml#{$size} {
    margin-left: $size + px;
  }
}
.gray {
  color: #999;
}
.greenText {
  color: #349940;
}
.textBlack {
  color: black;
}
.fs14 {
  font-size: 14px;
}

.toast {
  background: rgb(255, 202, 57);
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-radius: 9999px;

  span {
    cursor: pointer;
    padding-left: 5px;
    display: inline-block;
  }
  .close {
    padding: 5px 10px;
    margin-left: 10px;
    border: 1px solid #000;
    border-radius: 50%;
  }
}
</style>
