import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'

import Orders from './views/order/Orders.vue'
import OrderDetail from './views/order/OrderDetail.vue'

import JobList from '@/views/devices/JobList.vue'
import PhotoDocumentation from '@/views/devices/PhotoDocumentation.vue'

import Materials from '@/views/material/Materials.vue'
import NewMaterial from '@/views/material/NewMaterial.vue'
import ExportMaterial from '@/views/material/ExportMaterial.vue'
import Bin from '@/views/material/Bin.vue'

import Delivery from './views/delivery/Delivery.vue'
import DeliveryDetail from './views/delivery/DeliveryDetail.vue'

import Test from './views/Test.vue'

import { ElmComponent } from '@/elm/elmComponent'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/orders',
      name: 'Orders',
      component: Orders,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/orders/:orderId',
      name: 'OrderDetail',
      component: OrderDetail,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/orders/:orderId/material*',
      name: 'Material',
      component: ElmComponent(),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/orders/:orderId/devices/:deviceId',
      name: 'JobList',
      component: JobList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/orders/:orderId/devices/:deviceId/photo/:jobId',
      name: 'PhotoDocumentation',
      component: PhotoDocumentation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/materials',
      name: 'Materials',
      component: Materials,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/materials/:warehouseId/:binId',
      name: 'Bin',
      component: Bin,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/materials/new',
      name: 'NewMaterial',
      component: NewMaterial,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/materials/export',
      name: 'ExportMaterial',
      component: ExportMaterial,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/delivery',
      name: 'Lieferungen',
      component: Delivery,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/delivery/:orderId/:deliveryNr',
      name: 'DeliveryDetail',
      component: DeliveryDetail,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/test',
      name: 'Test',
      component: Test,
    },
    {
      path: '/elm*',
      name: 'Elm',
      component: ElmComponent(),
      props: () => {
        return {
          router: router,
          url: window.location.href,
        }
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  // save every route except login could work if we would save it always before login
  if (to.path !== '/login') {
    store.commit('setUrl', to.path)
  }
  if (to.name === 'Login') {
    if (store.getters.isLoggedIn) {
      next(store.state.url)
      return
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
