const state = {
  done: {},
}

const mutations = {
  setDone(state, done) {
    state.done = done || {}
  },
}

const actions = {
  setDoneState({ commit }, done) {
    commit("setDone", done)
  },
}

const getters = {
  done: state => state.done,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
