<template>
  <v-app class="documentation">
    <v-toolbar color="primary" dark dense fixed>
      <v-btn icon @click.native="backToJobs">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{$t('views.devices.photo_documentation.task', {task: job ? job.description : $t('views.devices.photo_documentation.load_data')})}}
      </v-toolbar-title>
    </v-toolbar>

    <template v-if="job && !uploading">
      <div class="documentation-content">
        <div v-if="!takingPhoto" style="text-align: center;">
          <figure> <img :src="job.reference_image" class="" /> </figure>

          <v-btn color="primary" @click="activateCamera">
            {{$t('views.devices.photo_documentation.start_camera')}}
          </v-btn>
        </div>
        <Camera
          v-else-if="takingPhoto"
          :overlay="job.overlay_image"
          :imageSelected="imageSelected"
          :abortTakingImage="abortTakingImage"
        />
      </div>
    </template>
    <template v-else>
      <div class="spinner-center" style="height:10rem">
        <v-progress-circular
          indeterminate
          size="40"
          color="primary"
          width="4"
        />
      </div>
    </template>
  </v-app>
</template>

<script>
import { assureDeviceData } from '../../store/modules/devices'
import Camera from './Camera'

export default {
  components: {
    Camera,
  },
  data() {
    return {
      uploading: false,
      error: false,
      takingPhoto: false,
    }
  },
  computed: {
    orderId() {
      return this.$route.params.orderId
    },
    deviceId() {
      return this.$route.params.deviceId
    },
    jobId() {
      return this.$route.params.jobId
    },
    job() {
      return this.$store.getters.getJob(this.deviceId, this.jobId)
    },
  },
  watch: {},
  mounted() {
    assureDeviceData(this.$store, this.$router, this.orderId)
  },
  methods: {
    activateCamera() {
      this.takingPhoto = true
      this.uploading = false
    },
    imageSelected(originalImg, overlayedImg) {
      this.uploading = true

      this.$store
        .dispatch('saveImage', {
          overlayedImg,
          originalImg,
          orderId: this.orderId,
          deviceId: this.deviceId,
          jobId: this.jobId,
        })
        .then(() => {
          this.$router.push(`/orders/${this.orderId}/devices/${this.deviceId}`)
        })
        .catch(err => {
          this.uploading = false
          this.error = true

          if (err.message === 'Request failed with status code 401') {
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/login')
            })
          } else {
            console.error('FIXME_PHOTO_DOCUMENTATION', err)
          }
        })

      this.takingPhoto = false
    },
    abortTakingImage() {
      this.takingPhoto = false
    },
    backToJobs() {
      this.$router.push(`/orders/${this.orderId}/devices/${this.deviceId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 200px;
}

.documentation .application--wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .v-toolbar--fixed {
    position: relative;
  }

  .documentation-content {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    position: relative;
  }
}

.spinner-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
