<template>
  <v-app>
    <v-content>
      <v-card>
        <v-toolbar color="primary" dark dense fixed>
          <v-btn icon to="/materials">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{$t('views.material.bin.inventory')}}</v-toolbar-title>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="articles"
          style="margin-top: 50px"
          :pagination.sync="pagination"
          hide-actions
        >
          <template slot="items" slot-scope="props">
            <td>
              <div style="font-weight: bold">{{ props.item.description }}</div>
              <div style="font-size: 80%; color: #666">{{
                props.item.vendor
              }}</div>
            </td>
            <td>{{ props.item.productCategory }}</td>
            <td class="text-xs-right">{{ props.item.count }}</td>
          </template>
        </v-data-table>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Bin',
  components: {},
  props: {},
  data() {
    return {
      headers: [
        { text: this.$t('views.material.bin.product'), value: 'description' },
        { text: this.$t('views.material.bin.category'), value: 'productCategory' },
        { text: '', value: 'count', align: 'right' },
      ],
      pagination: {
        rowsPerPage: -1,
      },
    }
  },
  computed: {
    ...mapGetters(['articles']),
  },
  watch: {},
  mounted() {
    this.$store.dispatch('getArticles', {
      warehouseId: this.$route.params.warehouseId,
      binId: this.$route.params.binId,
    })
  },
}
</script>

<style>
table.v-table thead td:not(:nth-child(1)),
table.v-table tbody td:not(:nth-child(1)),
table.v-table thead th:not(:nth-child(1)),
table.v-table tbody th:not(:nth-child(1)),
table.v-table thead td:first-child,
table.v-table tbody td:first-child,
table.v-table thead th:first-child,
table.v-table tbody th:first-child {
  padding: 0 8px;
}
</style>
