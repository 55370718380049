import { SendMsgToElm } from "./signatures";

export function registerEvents(send: SendMsgToElm) {
  window.addEventListener("online", () => {
    send({
      msg: "GlobalEventMsg",
      data: { msg: "EventOnline" },
    });
  });

  window.addEventListener("offline", () => {
    send({
      msg: "GlobalEventMsg",
      data: { msg: "EventOffline" },
    });
  });
}
