<template>
  <div :style="{ backgroundColor: bgColor }">
    <h3 class="center">
      <div>SLA:</div>
      <div v-if="slaTime === -1">{{$t('components.order.sla_color_block.none')}}</div>
      <div v-else><HumanDate :date="slaTime"/></div>
    </h3>
  </div>
</template>

<script>
import HumanDate from './HumanDate'
import { differenceInHours } from 'date-fns'

export default {
  props: {
    slaTime: [Date, Number],
    colored: Boolean,
  },
  components: {
    HumanDate,
  },
  computed: {
    bgColor() {
      if (!this.colored || this.slaTime === -1) {
        return ''
      }
      const differenceHours = differenceInHours(this.slaTime, Date.now())
      if (differenceHours > 24) {
        return ''
      } else if (differenceHours > 8) {
        return 'yellow'
      } else if (differenceHours > 4) {
        return 'orange'
      }
      return 'red'
    },
  },
}
</script>

<style scoped lang="css">
.center {
  text-align: center;
}
</style>
