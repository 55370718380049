import { Elm } from './src/App.elm'
import * as SyncEngineInterface from './sync_engine_kernel/sync_engine.ts'
import { config } from '../config'
import './src/Ui/UiComponents/signature_pad_component.ts'
import isEqual from '../lib/equality'
import { v4 as uuidv4 } from 'uuid';

//
// This is defined at the module level, so that the exact same Elm instance
// can be reused over the whole application. This will make it possible to
// reuse as much as possible and keep all the Elm state in one single instance.
//
// This will also help when implementing the sync engine.
//
let outerContainer = document.createElement('div')
let elmContainer = document.createElement('div')

//
// Set or read device ID
//
const keyName = "BtmDeviceId"
const maybeDeviceId = localStorage.getItem(keyName)
let deviceId = null

if (maybeDeviceId) {
  deviceId = maybeDeviceId
} else {
  deviceId = uuidv4()
  localStorage.setItem(keyName, deviceId)
}

const maybeLanguage = localStorage.getItem('i18n_local_language')

//
// This is necessary so that Elm can call "replaceChild" on something. In this
// case `elmContainer` gets completely overridden by the Elm application
// instance.
//
outerContainer.appendChild(elmContainer)
let href = window.location.href || ''

export let app = null

SyncEngineInterface.getModel(model => {
  app = Elm.App.init({
    node: elmContainer,
    flags: {
      url: href.replace('#', '/'),
      baseUrl: config.baseUrl,
      jwt: null,
      model: model,
      version: config.version,
      email: null,
      deviceId,
      language: maybeLanguage
    },
  })

  // TODO: implement sentry if this should fail...
  if (app && app.ports) {
    SyncEngineInterface.setup(app.ports)
  }
})

export function ElmComponent() {
  return {
    props: {
      flags: {
        type: Object,
        required: false,
      },
    },
    render: function(createElement) {
      return createElement('div')
    },
    data: function() {
      return {
        state: {
          oldFlags: null
        }
      }
    },
    watch: {
      //
      // To react on route changes we need to watch the vuejs $route property
      // because the app will try to reuse a component instance as much as
      // possible.
      //
      $route(to) {
        if (
          app &&
          app.ports.urlChange &&
          to &&
          window.location &&
          window.location.href
        ) {
          href = window.location.href || ''
          app.ports.urlChange.send(href.replace('#', '/'))
        }
      },

      //
      // We need to manually watch the props that should trigger an reaction on
      // the Elm side.
      //
      flags(newFlags) {
        let email = null

        if (
          this &&
          this.$store &&
          this.$store.state &&
          this.$store.state.user &&
          this.$store.state.user.email
        ) {
          email = this.$store.state.user.email
        }

        let data = {
          ...newFlags,
          url: href.replace('#', '/'),
          baseUrl: config.baseUrl,
          version: config.version,
          email,
          deviceId,
          language: maybeLanguage ? maybeLanguage : 'de'
        };

        //
        // This fixes a bug that lead to constantly reloading the stations from the server
        // whenever we changed the "stations done" state.
        // The flags rarely _actually_ change but if they do we absolutely need to reload data from
        // the server (since this most often happens when we switch to a new operation).
        //
        // The need to refresh with the lacking check if anything substantial changed lead
        // to a situation where it wasn't possible to delete a stations iteration because
        // the request would be fulfilled faster (because of the GET cache) than the new
        // model was written to the database.
        //
        const hasNewFlags =
          (!this.state.oldFlags || !(
            this.state.oldFlags.url === data.url &&
            this.state.oldFlags.email === data.email &&
            this.state.oldFlags.deviceId === data.deviceId &&
            ((this.state.oldFlags.operation && data.operation) && this.state.oldFlags.operation.id === data.operation.id) &&
            this.state.oldFlags.jwt === data.jwt
          ))

        if (app && app.ports && app.ports.in_ && hasNewFlags) {
          this.state.oldFlags = data
          href = window.location.href || ''
          app.ports.in_.send({
            msg: 'JsFlagsChange',
            data: data
          })
        }
      },
    },
    mounted: function() {
      //
      // Needed by vue to find the correct element to render into.
      //
      this.$el.dataset.app = true

      //
      // We need to add an additional container because otherwise vue looses
      // track of the rendering root and the router cannot render the component
      // changes (because Elm will completely take over the target container
      // and delete all properties on it - like the above data-app)
      //
      this.$el.appendChild(outerContainer)

      //
      // Since the elm app needs to be intialised async (because we need to load
      // data from indexed db) we need to defer this. Since we cannot make any
      // guarantees _when_ it becomes available, we poll until it is available.
      //
      const defer = () => {
        setTimeout(() => {
          //
          // Hook up the elm application with the vue router
          //

          if (app && app.ports && app.ports.navigateToLink && this.$router) {
            app.ports.navigateToLink.subscribe(target => {
              this.$router.push({ path: target }).catch(() => {
                // Just swallow the error when the same link is called again.
                // This should not be a problem for the Elm application.
              })
            })
          }

          //
          // Subscribe to the Elm done state for a station and then query it.
          //
          if (app && app.ports && app.ports.operationDoneState && this.$store) {
            app.ports.operationDoneState.subscribe(done => {
              const globalDoneState = JSON.parse(
                // Remove the observer from the object
                JSON.stringify(this.$store.state.stations.done || {})
              )
              const elmDoneState = JSON.parse(JSON.stringify(done || {}))

              //
              // Prevents event flooding because the state change would
              // also change the flags, which would trigger setting the
              // done state...
              //
              if (!isEqual(elmDoneState, globalDoneState)) {
                //
                // Prevents the event from bubbling back to Elm immediatly.
                // Otherwise it causes a bug, where you cannot delete a stations
                // content because it gets a flag passed with the old state.
                //
                setTimeout(() => {
                  this.$store.dispatch('setDoneState', done)
                }, 100)
              }
            })

            if (this.flags && this.flags.operation && this.flags.operation.id) {
              const operationId = this.flags.operation.id
              app.ports.in_.send({
                msg: 'JsGetFinishedState',
                data: operationId.toString(),
              })
            }
          }

          let email = null

          if (
            this &&
            this.$store &&
            this.$store.state &&
            this.$store.state.user &&
            this.$store.state.user.email
          ) {
            email = this.$store.state.user.email
          }

          if (
            app &&
            app.ports &&
            app.ports.in_ &&
            window.location &&
            window.location.href
          ) {
            const flags = this.$props.flags || {}
            href = window.location.href || ''
            app.ports.in_.send({
              msg: 'JsFlagsChange',
              data: {
                ...flags,
                url: href.replace('#', '/'),
                baseUrl: config.baseUrl,
                version: config.version,
                email,
                language: maybeLanguage ? maybeLanguage : 'de'
              },
            })
          }

          if (!app) {
            defer()
          }
        }, 1000)
      }

      defer()
    },
  }
}
