import axios from 'axios'
import qs from 'qs'
import { config } from '@/config'

function upload(job) {
  const url = `${config.RAILS_API}/api/v1/app/jobs/upload_image`
  const token = localStorage.getItem('railsToken')
  const options = {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    data: qs.stringify(job),
    url,
  }

  return axios(options)
}

export default upload
