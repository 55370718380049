//
// An IndexedDB based job queue
//

import Dexie from 'dexie'
import 'dexie-observable'
import picture from '@/sync/api/picture'
import signature from '@/sync/api/signature'
import isReachable from '@/lib/connectivity'
import store from '@/store/index'

let queues = new Dexie('BentomaxQueue')
let syncActive = false
let watcherActive = false
let isOffline = false

function setupDb() {
  queues = new Dexie('BentomaxQueue')
  queues.version(1).stores({
    jobs: 'id,done_at',
    signatures: 'id,ticket_id,signature_id,signed_at',
  })
}

function resetDatabase() {
  Dexie.delete('BentomaxQueue')
    .then(() => {
      setupDb();
      alert("Reset abgeschlossen.")
    })
}

setupDb();

//
// Add a job to its queue. Returns the Dexie promise
//
function addJob(job) {
  return queues.jobs.put(job)
}

//
// Add a signature to its queue. Returns the Dexie promise
//
function addSignature(sig) {
  return queues.signatures.put(sig)
}

function start() {
  if (syncActive || isOffline) return

  syncActive = true

  //
  // Implementation for the jobs queue
  //
  queues.jobs
    .orderBy('done_at')
    .first()
    .then(latestJob => {
      if (latestJob.job_type === 'picture') {
        picture(latestJob)
          .then(() => {
            syncActive = false
            queues.jobs.delete(latestJob.id)
            start()

            store.commit('updateJob', {
              deviceId: latestJob.deviceId,
              jobId: latestJob.id,
              newValue: {
                done: true,
                queued: false,
              },
            })
          })
          .catch(err => {
            console.error('PHOTO_UPLOAD_FAILED', err)
            syncActive = false

            //
            // Debounce for 5 seconds to avoid request flooding
            //
            setTimeout(start, 5000)
          })
      }
      // TODO: implement further job types to process
    })
    .catch(err => {
      console.info('NO_JOB_TO_PROCESS', err)
      syncActive = false
    })

  queues.signatures
    .orderBy('signed_at')
    .first()
    .then(latestSig => {
      if (latestSig) {
        signature(latestSig)
          .then(() => {
            syncActive = false
            queues.signatures.delete(
              `${latestSig.signature_id}_${latestSig.ticket_id}`
            )
            start()

            store.commit('updateSignature', {
              signature_id: latestSig.signature_id,
              ticket_id: latestSig.ticket_id,
              newValue: {
                done: true,
                queued: false,
              },
            })
          })
          .catch(err => {
            console.error('SIGNATURE_UPLOAD_FAILED', err.message)
            const msg = err.message || ''
            if (msg.includes('400') || msg.includes('403')) {
              // Delete if the request was malformed or if the actions has been forbidden on the server
              queues.signatures.delete(
                `${latestSig.signature_id}_${latestSig.ticket_id}`
              )
            } else {
              syncActive = false

              //
              // Debounce for 5 seconds to avoid request flooding
              //
              setTimeout(start, 5000)
            }
          })
      }
      // TODO: implement further job types to process
    })
    .catch(err => {
      console.info('NO_JOB_TO_PROCESS', err)
      syncActive = false
    })

  //
  // When anything gets added start again from here
  // TODO: when we have multiple queues we probably want them to run in parallel, or something like that
  //
  if (!watcherActive) {
    watcherActive = true

    queues.on('changes', changes => {
      changes.forEach(function(change) {
        switch (change.type) {
          case 1: // CREATED
            start()
            queues.jobs.count(count => {
              store.commit('updateQueuedUploads', count)
            })
            break
          case 2: // UPDATED
            break
          case 3: // DELETED
            queues.jobs.count(count => {
              store.commit('updateQueuedUploads', count)
            })
            break
        }
      })
    })
  }
}

function areUploadsQueued(yes, no) {
  queues.jobs.count(j => {
    if (j > 0) {
      yes(j)
    } else {
      queues.signatures.count(s => {
        s > 0 ? yes(s) : no()
      })
    }
  })
}

function getCurrentQueueValues() {
  queues.jobs.each(j => {
    store.commit('updateJob', {
      jobId: j.id,
      deviceId: j.deviceId,
      newValue: {
        ...j,
        queued: true,
        done: false,
      },
    })
  })

  queues.signatures.each(s => {
    store.commit('updateSignature', {
      signature_id: s.signature_id,
      ticket_id: s.ticket_id,
      newValue: {
        ...s,
        queued: true,
        done: false,
      },
    })
  })
}

//
// Handle beeing offline and coming back online
//
window.addEventListener('online', () => {
  isReachable().then(function(online) {
    if (online) {
      isOffline = false
      start()
    } else {
      isOffline = true
    }
  })
})

window.addEventListener('offline', () => {
  isOffline = true
})

export default {
  addJob,
  addSignature,
  start,
  syncActive,
  areUploadsQueued,
  getCurrentQueueValues,
  resetDatabase
}
