<template>
  <v-app>
    <v-content>
      <v-card>
        <v-toolbar color="primary" dark dense fixed>
          <v-btn icon to="/materials">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{$t('views.material.new_material.new_material')}}</v-toolbar-title>
        </v-toolbar>

        <v-form
          v-if="projects"
          ref="form"
          v-model="valid"
          style="padding: 62px 10px 0 10px"
          lazy-validation
          @submit.prevent.stop="submit"
        >
          <v-select
            v-model="projectId"
            outline
            :items="projects"
            item-text="name"
            item-value="id"
            :label="$t('views.material.new_material.project')"
            @change="projectChanged"
          ></v-select>
          <div>
            <v-text-field
              v-model="deliveryNote"
              :label="$t('views.material.new_material.delivery_note')"
              prepend-icon="event"
              style="margin-top: -6px"
              :rules="deliveryNoteRules"
            ></v-text-field>
            <v-dialog
                ref="dialog"
                v-model="modalDate"
                :return-value.sync="date"
                persistent
                lazy
                full-width
                width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="date"
                    :label="$t('views.material.new_material.delivery_time')"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    :rules="dateRules"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn flat color="primary" @click="modalDate = false">Cancel</v-btn>
                <v-btn flat color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="dialogArticle"
              v-model="modalArticle"
              :return-value.sync="templateId"
              persistent
              lazy
              fullscreen
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" round>
                  <i class="far fa-plus-circle" style="margin-right: 8px"></i>
                  {{$t('views.material.new_material.add_item')}}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.material.new_material.record_item')}}
                </v-card-title>
                <v-card-text>
                  <v-autocomplete
                    v-model="templateId"
                    :items="project.templates"
                    item-value="id"
                    item-text="description"
                    :label="$t('views.material.new_material.type')"
                    persistent-hint
                  ></v-autocomplete>

                  <div v-if="templateId">
                    <v-text-field
                      v-if="template.sn"
                      v-model="sn"
                      :label="$t('views.material.new_material.serial_number')"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="count"
                      type="number"
                      :label="$t('views.material.new_material.amount')"
                    ></v-text-field>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modalArticle = false"
                    >{{$t('views.material.new_material.abort')}}</v-btn
                  >
                  <v-btn flat color="primary" @click="addArticle"
                    >{{$t('views.material.new_material.add')}}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div style="padding: 10px 0">
              <v-list-tile v-for="article in articles" :key="article.id">
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ article.templateDescription }}
                    <span style="color: #999; margin-left: 10px"
                      >{{$tc('views.material.new_material.n_items', article.count, {n: article.count})}}</span
                    >
                  </v-list-tile-title>
                  <v-list-tile-sub-title v-if="article.sn"
                    >SN: {{ article.sn }}</v-list-tile-sub-title
                  >
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn icon ripple @click="deleteArticle(article)">
                    <v-icon color="grey lighten-1">delete</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </div>

            <div style="padding: 10px">
              <v-btn block type="submit" color="primary" dark round>
                {{$t('views.material.new_material.post_goods_receipt')}}
              </v-btn>
            </div>
          </div>
          <div>
            {{$t('views.material.new_material.material_receipt_posting_is_not_possible_no_storage_bin_assigned')}}
          </div>
        </v-form>
        <div v-else>
          <div style="padding: 0 10px; margin-top: 64px">
            {{$t('views.material.new_material.material_receipt_posting_is_not_possible_no_projects')}}
          </div>
        </div>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NewMaterial',
  components: {},
  props: {},
  data() {
    return {
      projectId: undefined,
      valid: true,
      deliveryNote: '',
      deliveryNoteRules: [v => !!v || this.$t('views.material.new_material.delivery_note_is_required')],
      date: '',
      dateRules: [v => !!v || this.$t('views.material.new_material.delivery_date_is_required')],
      modalDate: false,
      modalArticle: false,
      templateId: undefined,
      sn: '',
      count: 1,
      articles: [],
      currentId: 0,
    }
  },
  computed: {
    ...mapGetters(['projects']),
    project() {
      if (!this.projectId) return undefined;
      return this.projects.find(project => project.id === this.projectId)
    },
    template() {
      if (!this.templateId) return undefined;
      return this.project.templates.find(
        template => template.id === this.templateId
      )
    },
  },
  watch: {},
  mounted() {
    this.$store.dispatch('getProjects').then(() => {
      if (this.projects.length > 0) {
        this.projectId = this.projects[0].id
      } else {
        this.project = undefined
      }
    })
  },
  methods: {
    projectChanged() {
      console.log('project selected', this.project)
    },
    addArticle() {
      this.articles.push({
        id: this.currentId++,
        templateId: this.templateId,
        templateDescription: this.template.description,
        sn: this.template.sn ? this.sn : undefined,
        count: this.template.sn ? 1 : this.count,
      })
      this.$refs.dialogArticle.save(this.templateId)
    },
    deleteArticle(article) {
      const index = this.articles.indexOf(article);
      this.articles.splice(index, 1)
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('sendMaterial', {
            projectId: this.projectId,
            materials: {
              deliveryNote: this.deliveryNote,
              deliveryDate: this.date,
              materials: this.articles,
            },
          })
          .then(() => {
            console.log('sendMaterial finished');
            this.$router.push('/materials')
          })
      }
    },
  },
}
</script>
