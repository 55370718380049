/**
 * This module contains the definitions for our queues (which are
 * essentially caches to store jobs, signatures and possibly others).
 *
 * We need this caching layer because the data **must** reach the
 * server, due to compliance reasons. So anything that is deleted must
 *
 */

import Dexie from 'dexie'
import { SendDatabaseEvent, ReceiveDatabaseRequest } from './signatures'
import { DatabaseRequestMsg } from './messages_from_elm'
import { Picture, ModelCache } from './types'

class BentomaxDB extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  model: Dexie.Table<ModelCache, string> // number = type of the primkey
  pictures: Dexie.Table<Picture, string>

  constructor() {
    super('BentomaxDB')
    this.version(1).stores({
      model: 'id',
      pictures: 'id,picture_type',
    })
    // The following line is needed if your typescript
    // is compiled using babel instead of tsc:
    this.model = this.table('model')
    this.pictures = this.table('pictures')
  }
}

export const db = new BentomaxDB()

/**
 * This error handler is used to enforce totality in the switch statement
 * over the DatabaseQuery type.
 *
 * @param query any
 */
function throwBadQuery(query: never): never
function throwBadQuery(query: DatabaseRequestMsg) {
  throw new Error('Unknown database query: ' + query)
}

function toJpg(
  picture: Picture,
  success: (picture: Picture) => any,
  failure: () => any
) {
  let img = document.createElement('img') as HTMLImageElement
  const data = picture.data
  const src = data.image
  img.crossOrigin = 'Anonymous'
  img.onload = function() {
    let canvas = document.createElement('canvas') as HTMLCanvasElement
    let ctx = canvas.getContext('2d') as CanvasRenderingContext2D | null
    const { naturalHeight: imageHeight, naturalWidth: imageWidth } = img
    let ratio = 1
    const maxWidth = 800
    const maxHeight = 800

    if (imageWidth > maxWidth) {
      ratio = maxWidth / imageWidth
    } else if (imageHeight > maxHeight) {
      ratio = maxHeight / imageHeight
    }

    const newHeight = imageHeight * ratio
    const newWidth = imageWidth * ratio

    if (ctx) {
      canvas.height = newHeight
      canvas.width = newWidth

      // resizes the image to fit into the maxWidth and maxHeight values
      ctx.drawImage(
        img,
        0,
        0,
        imageWidth,
        imageHeight,
        0,
        0,
        newWidth,
        newHeight
      )

      success({
        ...picture,
        data: {
          ...data,
          image: canvas.toDataURL('image/jpeg'),
        },
      })
    } else {
      failure()
    }
  }
  img.src = src
  if (img.complete || img.complete === undefined) {
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
    img.src = src
  }
}

export function handleDatabaseQuery(
  query: DatabaseRequestMsg,
  send: SendDatabaseEvent
) {
  switch (query.msg) {
    case 'PutPicture':
      toJpg(
        query.data,
        picture => {
          db.pictures
            .put(picture)
            .then(() => {
              send({ msg: 'InsertSuccessful' })
            })
            .catch(e => {
              send({ msg: 'InsertFailure', data: e.toString() })
            })
        },
        () => {
          send({ msg: 'InsertFailure', data: 'Could not convert image to jpg' })
        }
      )
      break

    case 'GetPicture':
      db.pictures.get(query.photoId, picture => {
        if (picture && picture.data) {
          send({
            msg: 'DbLoadedPicture',
            data: picture.data,
          })
        } else {
          send({
            msg: 'DbPictureNotFound',
            data: query.photoId
          })
        }
      })
      break

    case 'DeletePhoto':
      db.pictures.delete(query.photoId).then(() => {
        send({ msg: 'DeletedSuccessfully' })
      })
      break

    case 'PutModel':
      db.model
        .put(query.data)
        .then(() => {
          send({ msg: 'InsertSuccessful' })
        })
        .catch(e => {
          send({ msg: 'InsertFailure', data: e.toString() })
        })
      break

    case 'DeleteModel':
      db.model.delete(query.data).then(() => {
        send({ msg: 'DeletedSuccessfully' })
      })
      break

    case 'LoadModel':
      db.model.get('model', data => {
        send({
          msg: 'DbModelLoaded',
          data: data ? data.model : null,
        })
      })
      break

    case 'GetOperationPictureCount':
      db.pictures
        .where('picture_type')
        .equals(query.operationId)
        .count(res => {
        })
      break

    default:
      throwBadQuery(query)
  }
}

export function getModel(cb: (model: ModelCache | null) => any) {
  db.model.get('model', data => {
    cb(data ? data.model : null)
  })
}
