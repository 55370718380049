<template>
  <v-app>
    <v-content>
      <v-card>
        <v-toolbar color="primary" dark dense fixed>
          <v-btn icon to="/materials">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{$t('views.material.export_material.record_return')}}</v-toolbar-title>
        </v-toolbar>
        <template v-if="isLoading">
          <div class="mt-5 pt-1" />
          <div class="text-xs-center mt-5" style="height:10.5rem;">
            <v-progress-circular indeterminate size="64" color="primary" width="6" />
          </div>
        </template>
        <template v-else>
          <v-text-field
            v-model="order"
            class="nopadding nomargin ml-3 mt-5"
            prepend-icon="fa-calendar-week"
            :label="$t('views.material.export_material.order_number')"
            single-line
            hide-details
          />
          <v-text-field
            v-model="parcel"
            class="nopadding nomargin ml-3"
            prepend-icon="fa-box-open"
            :label="$t('views.material.export_material.bin_tracking_number')"
            single-line
            hide-details
          />
          <v-data-table
            :headers="headers"
            :items="exportArticles"
            :rows-per-page-items="[]"
          >
            <template v-slot:items="props">
              <tr>
                <td>
                  <span>{{ props.item.description }}</span>
                  <v-divider />
                  <span>{{ props.item.ftext }}</span>
                </td>
                <td>
                  {{ props.item.sn }}
                </td>
                <td>
                  {{ props.item.status }}
                </td>
                <td>
                  <v-btn style="min-width:0;width:3rem" @click="deleteArticle(props.item)">
                    <v-icon color="error">
                      fa-trash
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
        <v-divider v-if="isLoading" class="mb-5" />
        <div>
          <v-btn
            color="primary"
            style="width:60%;margin:0;padding:0"
            :disabled="isLoading"
            @click="clickedFromList"
          >
            <div>
              <v-icon small style="margin-right:1rem">fa-plus</v-icon>
              {{$t('views.material.export_material.from_list')}}
            </div>
          </v-btn>

          <v-btn
            color="#BBBBBB"
            style="width:40%;margin:0;padding:0"
            :disabled="isLoading"
            @click="
              modalPart2 = true
            "
          >
            <div>
              {{$t('views.material.export_material.manually')}}
              <v-icon small style="margin-left:1rem">fa-plus</v-icon>
            </div>
          </v-btn>
        </div>
        <hr style="color: #9FA8DA" />
        <v-btn
          class="nopadding nomargin"
          style="margin-top:2rem"
          color="primary"
          block
          :disabled="
            !order ||
              !parcel ||
              !exportArticles ||
              exportArticles.length < 1 ||
              isLoading
          "
          @click="sendMail"
        >
          <div>
            <v-icon small class="mr-2">fa-plus</v-icon>
            {{$t('views.material.export_material.send')}}
          </div>
        </v-btn>
        <v-dialog v-model="modalPart2" persistent lazy fullscreen>
          <v-card v-if="modalPart2">
            <v-card-title class="headline grey lighten-2" primary-title>
              {{$t('views.material.export_material.exchanged_part')}}
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="tempRMA"
                label="RMA"
              />
              <v-text-field
                v-model="tempDescription"
                :label="$t('views.material.export_material.desription')"
                :rules="[rules.required]"
              />
              <v-text-field
                v-model="tempSN"
                :label="$t('views.material.export_material.serial_number')"
                :rules="[rules.required]"
              />
              <v-text-field
                v-model="tempFText"
                :label="$t('views.material.export_material.error_text')"
                :rules="[rules.required]"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn flat color="primary" @click="modalPart2 = false">
                Cancel
              </v-btn>
              <v-btn
                flat
                color="primary"
                :disabled="
                  (!tempRMA && !tempSN) ||
                  !tempDescription ||
                  !tempFText
                "
                @click="clickOKModalPart2"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="modalPart" persistent lazy fullscreen>
          <v-card v-if="modalPart">
            <v-card-title class="headline grey lighten-2" primary-title>
              {{$t('views.material.export_material.select_return_item')}}
            </v-card-title>
            <template v-if="modalIsLoading">
              <div class="text-xs-center" style="height:20rem">
                <v-progress-circular style="margin-top:5rem" indeterminate size="128" color="primary" width="8" />
              </div>
            </template>
            <template v-else>
              <v-data-table
                :headers="headers"
                :items="badParts"
                item-key="id"
                :rowsPerPageItems="[5]"
                must-sort
                lazy
                expand
              >
                <template v-slot:items="props">
                  <template v-if="props.item.locked">
                    <tr style="background-color:#CCCCCC">
                      <td>
                        {{ props.item.description }}
                      </td>
                      <td>
                        {{ props.item.sn }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                    :style="
                      props.item.selected === false
                        ? ''
                        : 'backgroundColor:#AED581'
                    "
                    @click="
                      props.item.selected === true
                        ? ((props.item.ftext = '')+(props.item.selected = false))
                        : (props.expanded = !props.expanded)
                    "
                    >
                      <td>
                        {{ props.item.description }}
                      </td>
                      <td>
                        {{ props.item.sn }}
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-slot:expand="props">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-text-field
                        v-model="props.item.ftext"
                        :label="$t('views.material.export_material.error_text')"
                        single-line
                        clearable
                        @click:clear="props.item.ftext = ''"
                      />
                    </v-list-tile-content>
                    <div>
                      <v-btn
                        class="min-w-0 w-4"
                        @click="
                          (props.item.selected = true) &&
                            (props.expanded = !props.expanded)
                        "
                        :disabled="props.item.ftext ? props.item.ftext.length < 4 : true"
                      >
                        <v-icon color="primary">
                          fa-check
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-tile>
                  <v-divider />
                </template>
              </v-data-table>
            </template>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn flat color="primary" @click="modalPart = false">
                Cancel
              </v-btn>
              <v-btn flat color="primary" @click="clickOKModalPart">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/de'

export default {
  name: 'ExportMaterial',
  components: {},
  props: {},
  data() {
    return {
      format,
      deLocale,
      projectId: undefined,
      valid: true,
      isLoading: false,
      modalIsLoading: false,
      // binId: undefined,
      // binRules: [v => !!v || 'Lagerplatz ist erforderlich'],
      deliveryNote: '',
      deliveryNoteRules: [v => !!v || this.$t('views.material.export_material.delivery_note_is_required')],
      date: '',
      dateRules: [v => !!v || this.$t('views.material.export_material.delivery_date_is_required')],
      modalDate: false,
      modalArticle: false,
      templateId: undefined,
      sn: '',
      count: 1,
      articles: [],
      parcel: '', // can't use the word 'package' it is a reserved word
      order: '',
      currentId: 0,
      tempRMA: '',
      tempDescription: '',
      tempSN: '',
      tempFText: '',
      modalPart: false,
      modalPart2: false,
      badParts: [],
      rules: {
        required: value => !!value || this.$t('views.material.export_material.mandatory_field'),
      },
      exportArticles: [],
      exportArticles2: [
        {
          'rma':'UDC-31400',
          'description':'Hamsterkasse',
          'sn':'1005-23415',
          'status':'Defekt'
        },
        {
          'rma':'AF-1450',
          'description':'Monitor FX415',
          'sn':'1335-71215',
          'status':'Defekt'
        },
        {
          'rma':'AF-1451',
          'description':'Monitor FX415',
          'sn':'1336-61415',
          'status':'Defekt'
        },
      ],
      headers: [
        {
          text: this.$t('views.material.export_material.desription'),
          value: 'description'
        },
        {
          text: 'SN',
          value: 'sn'
        },
      ],
      headers2: [
        {
          text: 'RMA / SN',
          align: 'left',
          value: 'rma'
        },
        {
          text: this.$t('views.material.export_material.desription'),
          value: 'description'
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['projects']),
    project() {
      if (!this.projectId) return undefined;
      return this.projects.find(project => project.id === this.projectId)
    },
    template() {
      if (!this.templateId) return undefined;
      return this.project.templates.find(
        template => template.id === this.templateId
      )
    },
    warehouses() {
      return this.$store.getters.warehouses
    },
  },
  created: function() {
    this.$store
      .dispatch('getWarehouses')
      .then(() => {})
  },
  watch: {},
  mounted() {
    this.$store.dispatch('getProjects').then(() => {
      if (this.projects.length > 0) {
        this.projectId = this.projects[0].id
      } else {
        this.project = undefined
      }
    })
  },
  methods: {
    sendMail(){
      var articles = '';
      this.isLoading = true;
      articles += "<table><tr><th>RMA</th><th>Seriennummer</th><th>{{$t('views.material.export_material.description</th><th>Fehlertext</th></tr>";
      for (let article of this.exportArticles){
        articles += "<tr><td>"+article.rma
          +"</td><td>"+article.sn
          +"</td><td>"+article.description
          +"</td><td>"+article.ftext
          +"</td></tr>"
      }
      articles += "</table>";
      Email.send({
        SecureToken : "da376082-b227-42f9-a4d7-7722ac4f2a66",
        To : 'helpdesk@bentomax.de',
        From : "toni_db@bentomax.de",
        Subject : "Materialausgang: "+this.order,
        Body : "Auftrags-ID: "+this.order+"<br>"
          +"Sendungsnummer: "+this.parcel+"<br>"
          +"Techniker: "+(this.$store.state.user.name ? this.$store.state.user.name : 'unbekannt')+"<br>"
          +"Nutzer-Email: "+(this.$store.state.user.email ? this.$store.state.user.email : 'unbekannt')+"<br>"
          +"Zeit: "+format(Date.now(), 'D. MMM YYYY HH:mm', {
              locale: deLocale,
            }) +"<br><br>"
          +articles
      }).then(
        () => {
          alert(this.$t('views.material.export_material.retoure_alert'));
          this.order = '';
          this.parcel = '';
          this.exportArticles = [];
          this.isLoading = false
        }
      );
    },
    checkBadParts() {
      this.badParts = [];
      for (let warehouse of this.warehouses) {
        for (let bin of warehouse.bins) {
          this.$store
            .dispatch('getArticles', {
              warehouseId: warehouse.id,
              binId: bin.id,
              complete: true,
            })
            .then(articles => {
              for (let article of articles) {
                if (article.badPart) {
                  Vue.set(article, 'ftext', '');
                  Vue.set(article, 'selected', false);
                  Vue.set(article, 'locked', false);
                  Vue.set(article, 'warehouseLabel', warehouse.label);
                  Vue.set(article, 'binLabel', bin.label);
                  Vue.set(article, 'warehouseId', warehouse.id);
                  Vue.set(article, 'binId', bin.id);
                  this.badParts.push(article)
                }
              }
            })
        }
      }
      this.badParts.sort(function(a, b){
        if (a.description < b.description) { return -1; }
        if (a.description > b.description) { return 1; }
        return 0;
      }).map((item, index) => ({
        id: index,
        ...item
      }));
      this.modalIsLoading = false

    },
    lockSelected() {
      for (let article of this.badParts){
        if (article.selected) article.locked = true
      }
      this.modalIsLoading = false;
      this.modalPart = true
    },
    addBadParts() {
      for (let article of this.badParts){
        if (article.selected && !article.locked) this.exportArticles.push(article)
      }
    },
    addTemp() {
      let temp = {};
      temp.rma = this.tempRMA;
      temp.description = this.tempDescription;
      temp.sn = this.tempSN;
      temp.ftext = this.tempFText;
      this.tempRMA = '';
      this.tempDescription = '';
      this.tempSN = '';
      this.tempFText = '';
      // this.exportArticles = this.exportArticles2
      this.exportArticles.push(temp)
    },
    projectChanged() {
      console.log('project selected', this.project)
    },
    addArticle() {
      this.articles.push({
        id: this.currentId++,
        templateId: this.templateId,
        templateDescription: this.template.description,
        sn: this.template.sn ? this.sn : undefined,
        count: this.template.sn ? 1 : this.count,
      });
      this.$refs.dialogArticle.save(this.templateId)
    },
    deleteArticle(article) {
      article.ftext = '';
      article.selected = false;
      article.locked = false;
      const index = this.exportArticles.indexOf(article);
      this.exportArticles.splice(index, 1)
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('sendMaterial', {
            projectId: this.projectId,
            materials: {
              deliveryNote: this.deliveryNote,
              deliveryDate: this.date,
              materials: this.articles,
            },
          })
          .then(() => {
            console.log('sendMaterial finished');
            this.$router.push('/materials')
          })
      }
    },
    clickedFromList() {
      this.modalIsLoading = true;
      this.tempRMA = '';
      this.tempDescription = '';
      this.tempSN = '';
      // this.tempStatus = '';
      this.badParts[0] ? this.lockSelected() : this.checkBadParts();
      this.modalPart = true;
    },
    clickOKModalPart() {
      this.addBadParts();
      this.modalPart = false;
    },
    clickOKModalPart2() {
      this.addTemp();
      this.modalPart2 = false;
    }
  },
}
</script>
