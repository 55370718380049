<template>
  <span> {{ humanDay }}, {{ humanTime }} </span>
</template>

<script>
import { format, differenceInCalendarDays } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en'

export default {
  props: {
    date: [Date, Number],
  },
  computed: {
    days() {
      return differenceInCalendarDays(this.date, new Date())
    },
    // Human readable day (language dependent)
    humanDay() {
      if (this.$i18n && this.$i18n.locale == 'en') {
        return this.humanDayEnglishFormat()
      } else {
        return this.humanDayGermanFormat()
      }
    },
    // Human readable time (language dependent)
    humanTime() {
      if (this.$i18n && this.$i18n.locale == 'en') {
        return this.humanTimeEnglishFormat()
      } else {
        return this.humanTimeGermanFormat()
      }
    }
  },
  methods: {
    // Human readable day in german format
    humanDayGermanFormat() {
      if (this.days > 4) return format(this.date, 'D.MMM', { locale: deLocale })
      if (this.days >= 2) return format(this.date, 'dddd', { locale: deLocale })
      if (this.days === 1) return 'Morgen'
      if (this.days === 0) return 'Heute'
      if (this.days === -1) return 'Gestern'
      return format(this.date, 'D MMM.', { locale: deLocale })
    },
    // Human readable day in english format
    humanDayEnglishFormat() {
      if (this.days > 4) return format(this.date, 'MMM D', { locale: enLocale })
      if (this.days >= 2) return format(this.date, 'dddd', { locale: enLocale })
      if (this.days === 1) return 'Tomorrow'
      if (this.days === 0) return 'Today'
      if (this.days === -1) return 'Yesterday'
      return format(this.date, 'MMM. D', { locale: enLocale })
    },
    // Human readable time in english format
    humanTimeEnglishFormat() {
      return format(this.date, 'h:mmA', { locale: enLocale })
    },
    // Human readable time in german format
    humanTimeGermanFormat() {
      return format(this.date, 'HH:mm', { locale: deLocale }) + ' Uhr'
    },
  }
}
</script>
