import { register } from 'register-service-worker'
import { config } from './config'

function checkVersion() {
  let tenMinutes = 600000
  setTimeout(function() {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', '/version?_=' + new Date().getTime())

    xhr.timeout = 2000

    xhr.onload = function() {
      if (this.response.trim() !== config.version) {
        if (confirm('Eine neue Version ist verfügbar. Neu laden?')) {
          window.location.reload()
        }
      }
    }

    xhr.onloadend = function(e) {
      checkVersion()
    }

    console.log('onload and ontimeout registered')
    xhr.send()
  }, tenMinutes)
}

if (config.isProduction()) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      console.log('Check version now!')
      checkVersion()
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; Refreshing now.')
      if (registration || registration.waiting) {
        // This tells the service worker that it should use the new
        // version on the next reload. Otherwise it is always waiting
        // for a hard refresh...
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
