<template>
  <div>
    <v-list-tile @click="openJobList(device.id)">
      <v-list-tile-content>
        <v-list-tile-title>
          {{ this.getDeviceTitle(device) }}
        </v-list-tile-title>
        <v-list-tile-sub-title>
          {{
            device.jobs
              ? $t('views.devices.device_list.x_of_y_jobs_done', {
                x: device.jobs.filter(job => {
                    return job.job_type !== 'checklist_info'
                  })
                  .reduce(
                    (acc, job) => (job.done || job.queued ? acc + 1 : acc),
                    0
                  ),
                y: device.jobs.filter(job => {
                    return job.job_type !== 'checklist_info'
                  }).length}
                )
              : '-'
          }}
        </v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider :key="device.id + 'divider'" />
  </div>
</template>

<script>
export default {
  components: {},
  props: ['device'],
  data() {
    return {}
  },
  computed: {
    orderId() {
      return this.$route.params.orderId
    },
    activeOrder() {
      return this.$store.state.devices.activeOrder
    },
  },
  watch: {},
  mounted() {},
  methods: {
    openJobList(deviceId) {
      this.$router.push(`/orders/${this.orderId}/devices/${deviceId}`)
    },
    backToOrder() {
      this.$router.push(`/orders/${this.orderId}`)
    },
    getDeviceTitle(device) {
      const prefix = device.identifier
      const sub_identifier = device.sub_identifier || ''
      const suffix = device.serial_number
        ? $t('views.devices.device_list.serial_number', {serial_number: device.serial_number})
        : ''

      return prefix + ` ${sub_identifier} ` + suffix
    },
  },
}
</script>

<style lang="scss"></style>
