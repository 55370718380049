<template>
  <basicLayout>
    <template v-if="isLoading">
      <v-card>
        <v-layout class="v-date-picker-header theme--light">
          <v-flex shrink>
            <button class="v-btn v-btn--icon theme--light" type="button">
              <div class="v-btn__content">
                &lt;
              </div>
            </button>
          </v-flex>
          <v-flex>
            <div class="v-date-picker-header__value">
              <button type="button">{{$t('views.order.orders.is_loaded')}}</button>
            </div>
          </v-flex>
          <v-flex shrink>
            <button class="v-btn v-btn--icon theme--light" type="button">
              <div class="v-btn__content">
                &gt;
              </div>
            </button>
          </v-flex>
        </v-layout>
      </v-card>
      <v-divider />
      <v-card style="height:1.5rem; padding:0; margin:0; text-align:center">
        \/
      </v-card>
      <div class="text-xs-center" style="height:20rem">
        <v-progress-circular
          class="mt-5"
          indeterminate
          size="128"
          color="primary"
          width="8"
        />
      </div>
    </template>
    <template v-else>
      <v-flex
        v-touch="{
          up: () => (expand = false),
          down: () => (expand = true),
        }"
        class="picker-wrapper"
      >
        <v-date-picker
          v-show="expand"
          ref="picker"
          v-model="date"
          color="blue darken-4"
          :pickerDate.sync="pickerDate"
          fullWidth
          :events="arrayEvents"
          firstDayOfWeek="1"
          :locale="datePickerLocale()"
          eventColor="green lighten-1"
          :showWeek="true"
          :showCurrent="true"
          type="date"
          noTitle
        />
        <v-card v-show="!expand">
          <v-layout class="v-date-picker-header theme--light">
            <v-flex shrink>
              <button
                class="v-btn v-btn--icon theme--light"
                type="button"
                @click="subDay"
              >
                <div class="v-btn__content">
                  <i class="v-icon material-icons theme--light">
                    chevron_left
                  </i>
                </div>
              </button>
            </v-flex>
            <v-flex>
              <div class="v-date-picker-header__value">
                <button type="button" @click="toggleExpand">{{
                  formatTitleDate(date)
                }}</button>
              </div>
            </v-flex>
            <v-flex shrink>
              <button
                class="v-btn v-btn--icon theme--light"
                type="button"
                @click="addDay"
              >
                <div class="v-btn__content">
                  <i class="v-icon material-icons theme--light">
                    chevron_right
                  </i>
                </div>
              </button>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-card
        style="height:1.5rem; padding:0; margin:0; text-align:center"
        @click="toggleExpand"
      >
        <v-icon v-show="!expand" style="height:1.4rem;" color="primary">
          fa-angle-down
        </v-icon>
        <v-icon v-show="expand" style="height:1.4rem;" color="primary">
          fa-angle-up
        </v-icon>
      </v-card>
      <v-flex
        v-touch="{
          left: () => addDay(),
          right: () => subDay(),
        }"
        class="operations-listing-wrapper"
        :style="
          !expand
            ? 'max-height: calc(100vh - 168px);'
            : 'max-height: calc(100vh - 394px);'
        "
        style="background: white"
      >
        <div class="pt-3" style="display:flex">
          <div class="body-2 ml-3">
            {{
              filteredOperations.length === 0
                ? $t('views.order.orders.no_operation')
                : filteredOperations.length === 1
                ? $t('views.order.orders.one_operation')
                : $t('views.order.orders.n_operations', {n: filteredOperations.length})
            }}
          </div>
          <v-spacer />
          <div class="body-2 mr-3">
            {{
              filteredOperations.filter(u => u.reportClosed).length === 0
                ? ``
                : `${
                    filteredOperations.filter(u => u.reportClosed).length
                  } ${$t('views.order.orders.done_2')}`
            }}
          </div>
        </div>
        <v-divider />
        <div class="tab-pane">
          <div v-if="filteredOperations.length != 0" class="schedule-list">
            <div
              v-for="operation in sortedOperations"
              :key="operation.id"
              class="schedule-item"
              @click.stop="openOrder(operation.id)"
            >
              <div class="schedule-item-flex">
                <v-container
                  class="details-wrapper"
                  :style="
                    operation.reportClosed ? 'background-color:#D0D0D0' : ''
                  "
                >
                  <v-layout justify-space-between column fill-height shrink>
                    <v-flex v-if="!operation.reportClosed" class="time" shrink>
                      {{$t('views.order.orders.sceduled')}}<br />
                      <span>{{
                        format(operation.operationDate, 'HH:mm')
                      }}</span>
                      <br />
                      <span class="project-label">{{
                        operation.ticket_order_nr
                          ? operation.ticket_order_nr.substring(0, 3)
                          : ''
                      }}</span>
                    </v-flex>
                    <v-flex />
                    <v-flex class="time" shrink>
                      <template v-if="operation.sla === -1" />
                      <template v-else>
                        SLA:<br />
                        <template
                          v-if="
                            format(date, 'DD.MM.YYYY', { locale: deLocale }) !==
                              format(operation.sla, 'DD.MM.YYYY', {
                                locale: deLocale,
                              })
                          "
                        >
                          {{ format(operation.sla, 'DD.MM.') }}<br />
                        </template>
                        {{ format(operation.sla, 'HH:mm') }}
                      </template>
                    </v-flex>
                  </v-layout>
                  <v-layout column class="operation-details">
                    <div class="client">
                      {{ operation.address.company }}
                    </div>
                    <div class="city">
                      {{ operation.address.street }}
                      {{ operation.address.streetNo }}<br />
                      {{ operation.address.zip }}
                      {{ operation.address.city }}
                    </div>
                    <div
                      v-if="!operation.reportClosed"
                      class="description fade"
                    >
                      {{ operation.description }}
                    </div>
                    <!--<v-btn depressed color="grey lighten-3" small>Details</v-btn>-->
                    <!--<v-btn depressed color="blue lighten-4" small>Report</v-btn>-->
                    <div
                      v-if="!operation.reportClosed"
                      v-show="operation.deliveries.length > 0"
                    >
                      {{$t('views.order.orders.deliveries')}} {{ operation.deliveries.length }}
                    </div>
                  </v-layout>
                  <div
                    style="display:flex;flex-direction:column;width:53px;margin-right:0.5rem;text-align:center"
                  >
                    <v-icon v-if="operation.reportClosed" color="green" large>
                      far fa-check-circle
                    </v-icon>
                    <v-icon v-else large>
                      far fa-circle
                    </v-icon>
                    <h4 v-if="operation.reportClosed">{{$t('views.order.orders.done')}}</h4>
                  </div>
                </v-container>
              </div>
            </div>
          </div>
          <div v-else class="no-orders-placeholder">
            {{$t('views.order.orders.no_operation_for_day')}}
          </div>
        </div>
        <UploadProgress
          v-if="checkUploadQueue"
          :uploadsInProgress="inProgress"
        />
      </v-flex>
    </template>
  </basicLayout>
</template>

<script>
import basicLayout from '@/layouts/basicLayout'
import { mapState } from 'vuex'
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en'
import UploadProgress from '@/views/devices/UploadProgress'
import sync from '@/sync/sync'

export default {
  name: 'Orders',
  components: { basicLayout, UploadProgress },
  data: () => ({
    date: '',
    pickerDate: '',
    format,
    dialog: false,
    expand: false,
    deLocale,
    finished: 'd',
    isLoading: true,
    checkUploadQueue: false,
    inProgress: 0,
  }),

  mounted() {
    this.areUploadsQueued()
  },

  computed: {
    ...mapState(['operations']),
    filteredOperations() {
      return this.$store.getters.filterOperationsByDate(this.date)
    },
    sortedOperations() {
      return this.filteredOperations
        .filter(u => !u.reportClosed)
        .concat(this.filteredOperations.filter(u => u.reportClosed))
    },
    arrayEvents() {
      return this.$store.getters.getEventsByYearMonth(this.pickerDate)
    },
  },
  created: function() {
    this.getdata()
    this.date = new Date().toISOString().substr(0, 10)
  },

  methods: {
    areUploadsQueued() {
      sync.areUploadsQueued(
        count => {
          this.inProgress = count
          this.checkUploadQueue = true
        },
        () => {
          this.checkUploadQueue = false
        }
      )

      setTimeout(this.areUploadsQueued, 10000)
    },
    addDay() {
      let d = new Date(this.date)
      d.setDate(d.getDate() + 1)
      d = new Date(d).toISOString().substr(0, 10)
      this.date = d
    },
    subDay() {
      let d = new Date(this.date)
      d.setDate(d.getDate() - 1)
      d = new Date(d).toISOString().substr(0, 10)
      this.date = d
    },
    formatTitleDate(date) {
      if (this.$i18n.locale == 'de') {
        return format(date, 'D. MMMM YYYY', { locale: deLocale })
      } else {
        return format(date, 'MMMM D. YYYY', { locale: enLocale })
      }
    },
    datePickerLocale() {
      if (this.$i18n.locale == 'de') {
        return 'de-de'
      } else {
        return 'en-en'
      }
    },
    shorten(str) {
      return str.slice(0, 88)
    },
    toggleExpand() {
      if (this.expand === true) this.expand = false
      else this.expand = true
    },
    getdata() {
      this.$store.dispatch('getOperations').then(() => {
        // for (let operation in this.filteredOperations) {
        //   this.finished = this.finished + 'test'
        // }
        this.isLoading = false
      })
    },
    openOrder(orderId) {
      this.$router.push('/orders/' + orderId)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-date-picker-table {
  height: auto;
  padding-bottom: 0.5rem;
}

.v-date-picker-header {
  padding: 2px 16px;
}

.picker-wrapper {
  border-bottom: 2px solid #f1f5f8;
}
.operations-listing-wrapper {
  height: 100%;
  /*max-height: calc(100vh - 352px);*/
  /*max-height: calc(100vh - 394px);*/
  overflow: scroll;
}

.tab-pane {
  display: flex;
  padding-bottom: 20px;
}
.schedule-list {
  display: block;
  width: 100%;
  padding: 10px 0 0 0;
}
.schedule-item {
  display: flex;
  width: 100%;
  min-height: 50px;
  border-bottom: 2px solid #e1e5e8;

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }
}

.schedule-item-flex {
  display: flex;
  width: 100%;
}

.schedule-item-flex > div:first-child {
  width: 70%;
  border-right: 2px solid #c3c8d7;
}

.details-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0;
}

.sla-wrapper {
  width: 30%;
  padding: 0.5rem;
}

.sla-wrapper > p {
  /*font-size: 1.1rem;*/
  /*font-weight: bold;*/
}

.time {
  padding: 0 0.5rem;
  font-size: 12px;
  color: #22292f;
  text-align: center;
}
.time span {
  margin: auto;
}

.operation-details {
  flex: 1;
  margin-left: 1rem;
  line-height: 1.3;
  color: #22292f;
}

.city {
  font-weight: bold;
  margin-bottom: 0.25em;
}

.fade {
  position: relative;
  //  2.3 for two lines, 4.5 for three lines
  height: 3.5rem;
  overflow: hidden;
}

.fade:after {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 1.1rem;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
}

.address {
  margin-bottom: 0.25em;
  font-weight: bold;
  color: #22292f;
}

.client {
  font-size: 1.1rem;
  font-weight: bold;
}

.description {
  margin-bottom: 0.5em;
  font-size: 0.9rem;
  color: #3d4852;
}

.ta-center {
  text-align: center;
}

.no-orders-placeholder {
  padding: 4em 2em;
  margin: 0 auto;
  font-size: 14px;
  color: #606f7b;
  text-align: center;
}

.v-btn--small {
  margin-left: 0;
}

.project-label {
  background: #f7f7f7;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 1px 3px;
  margin-top: 5px;
  display: block;
  border-radius: 3px;
}
</style>
