<template>
  <v-bottom-nav :active.sync="bottomNav" :value="true" absolute color="white">
    <v-btn to="/Orders" flat color="teal" value="nearby">
      <span>{{$t('components.bottomNav.jobs')}}</span>
      <v-badge right color="red">
        <span v-if="unfinishedOperationsToday.length > 0" slot="badge">
          {{ unfinishedOperationsToday.length }}
        </span>
        <v-icon>calendar_today</v-icon>
      </v-badge>
    </v-btn>
    <v-btn to="/materials" flat color="teal" value="nearby">
      <span>Material</span>
      <v-icon>widgets</v-icon>
    </v-btn>
    <v-btn to="/delivery" flat color="teal" value="nearby">
      <span>{{$t('components.bottomNav.deliveries')}}</span>
      <v-badge right color="red">
        <span v-if="deliveryCount > 0" slot="badge">{{ deliveryCount }}</span>
        <v-icon>local_shipping</v-icon>
      </v-badge>
    </v-btn>
    <!-- <v-btn to="/history" flat>
      <span class="mt-1">Historie</span>
      <i class="fal  fa-wrench fa-lg"></i>
    </v-btn> -->
  </v-bottom-nav>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      bottomNav: 'recent',
    }
  },
  computed: {
    ...mapGetters(['unfinishedOperationsToday', 'deliveryCount']),
  },
}
</script>

<style>
.icon-btn {
  display: flex;
  flex-direction: column;
}

.v-item-group.v-bottom-nav .v-btn--active .v-btn__content {
  font-size: 12px;
  font-weight: bold;
  color: #22292f;
}
.fal {
  height: 28px;
  padding: 10px 0 5px 0;
  font-size: 16px;
}

.v-item-group.v-bottom-nav .v-btn--active .v-btn__content .fal {
  font-weight: 400;
}
</style>
