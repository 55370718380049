<template>
  <basicLayout>
    <v-card>
      <v-list two-line subheader>
        <div v-for="project in projects" :key="project.id">
          <v-subheader style="background-color: antiquewhite">{{$t('views.delivery.delivery.project')}}: {{ project.name }}</v-subheader>
          <v-list-tile v-for="delivery in project.deliveries" :key="delivery.deliveryNr" :to="`/delivery/${delivery.operation.id}/${delivery.deliveryNr}`">
            <v-list-tile-content>
              <v-list-tile-title>
                {{ delivery.deliveryNr }}
                <span class="gray ml10">{{$tc('views.delivery.delivery.n_items', delivery.countArticles, {n: delivery.countArticles})}}</span>
              </v-list-tile-title>
              <v-list-tile-sub-title>{{$t('views.delivery.delivery.operation_at_date_in_city', {date: getLocalDate(delivery.operation.date), city:delivery.operation.city})}}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile v-if="project.deliveries.length === 0">
            <v-list-tile-content>
              <v-list-tile-title class="gray">
                {{$t('views.delivery.delivery.no_deliveries_for_project')}}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </v-list>
    </v-card>
  </basicLayout>
</template>

<script>
/* eslint-disable */
import basicLayout from '@/layouts/basicLayout'
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en'

export default {
  components: { basicLayout },
  data: () => ({
    format
  }),
  mounted() {
    this.$store.dispatch('getProjects');
  },
  computed: {
    ...mapGetters(['projects']),
  },
  methods: {
    getLocalDate(date){
      if (this.$i18n.locale == 'en') {
        return format(date, 'MMM. D', {locale: enLocale,})
      } else {
        return format(date, 'D. MMM', {locale: deLocale,})
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>
