import axios from 'axios'
import qs from 'qs'
import { format, isToday } from 'date-fns'
import { config } from '../../config'

const state = {
  operations: [],
}

const mutations = {
  setOperations(state, { operations, operationFromDetailPage }) {
    // if it's called from the detailpage (by refreshing)
    if (operationFromDetailPage) {
      for (let i = 0; i < operations.length; i++) {
        if (operations[i].index === operationFromDetailPage.index) {
          operations[i].report = operationFromDetailPage.report
          break
        }
      }
    }
    state.operations = operations
  },
  setOperation(state, newOperation) {
    if (state.operations.length > 0) {
      const index = state.operations.findIndex(
        operation => operation.id === newOperation.id
      )
      state.operations[index] = newOperation
    }
  },
}

const actions = {
  getOperations({ commit }, operationFromDetailPage) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations`
      const timestamp = new Date().getTime()
      const options = {
        method: 'GET',
        params: {
          jwt_token: token,
          timestamp,
        },
        url,
      }

      localStorage.setItem('lastOperationFetch', timestamp)

      axios(options)
        .then(res => {
          const { operations } = JSON.parse(JSON.stringify(res)).data

          commit('setOperations', {
            operations,
            operationFromDetailPage,
          })
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOperation({ rootState, commit, dispatch, state }, id) {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        params: {
          jwt_token: rootState.token,
        },
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        url: `${config.API}/operations/${id}`,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          commit('setOperation', newOperation)
          if (state.operations.length === 0) {
            dispatch('getOperations', newOperation)
          }
          resolve(newOperation)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  reportOrderDetailTime({ commit }, { operationId, type }) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/updateTimes`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          jwt_token,
          type,
          time: Date.now().valueOf(),
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },
  reportCustomOrderDetailTime({ commit }, { operationId, type, customDate }) {
    const jwt_token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/updateTimes`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          jwt_token,
          type,
          time: customDate,
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  reportOrderDetail({ commit }, { operationId, type, value }) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/updateReport`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          jwt_token,
          type,
          value,
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  reportRework({ commit }, { operationId, type, value, reworkId }) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/updateReport`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          jwt_token,
          type,
          value,
          reworkId,
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  reportMaterial(
    { commit },
    { operationId, materialId, reportStatus, materialError }
  ) {
    const jwt_token = localStorage.getItem('token')

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/updateReport`
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          jwt_token,
          reportStatus,
          materialId,
          materialError,
          type: 'material',
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  reportField({ commit }, { operationId, fieldId, fieldValue }) {
    const jwt_token = localStorage.getItem('token')

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/updateReport`
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          jwt_token,
          fieldId,
          fieldValue,
          type: 'field',
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  reportParts({ commit }, { operationId, parts }) {
    const jwt_token = localStorage.getItem('token')

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/updateReport`
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          jwt_token,
          parts: JSON.stringify(parts),
          type: 'parts',
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  addMaterialFromWarehouse({ commit }, { operationId, articleId }) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/addMaterialFromWarehouse`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          jwt_token,
          articleId,
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  removeMaterialFromWarehouse({ commit }, { operationId, materialId }) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/removeMaterialFromWarehouse`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          jwt_token,
          materialId,
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  replaceMaterial(
    { commit },
    { operationId, materialId, templateId, sn, description }
  ) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/replaceMaterial`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          jwt_token,
          materialId,
          templateId,
          sn,
          description,
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  deleteFile({ commit }, { operationId, filename }) {
    const jwt_token = localStorage.getItem('token')

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${operationId}/deleteFile`
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          jwt_token,
          filename,
        }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  uploadSignature({ commit, rootState }, { id, data }) {
    const jwt_token = rootState.token // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.API}/operations/${id}/uploadSignature`
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        // params: { jwt_token, data },
        data: qs.stringify({ data, jwt_token }),
        url,
      }
      axios(options)
        .then(({ data: newOperation }) => {
          // TODO set only one operation
          commit('setOperation', newOperation)
          resolve(newOperation)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },

  closeOrder({ commit }, values) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line
    const {
      id,
      cqc,
      distance,
      status,
      statusArray,
      finished,
      onSite,
      repaired,
      travelStart,
    } = values

    const isTimeSet =
      travelStart !== -1 && onSite !== -1 && repaired !== -1 && finished !== -1
    // add signature
    return new Promise((resolve, reject) => {
      console.log('cqc.length: ', cqc.length)
      console.log('distance: ', distance)
      console.log('statusArray[status]: ', statusArray[status])
      console.log('isTimeSet: ', isTimeSet)
      if (
        /*cqc.length > 0 && */ distance > 0 &&
        statusArray[status] &&
        isTimeSet
      ) {
        const url = `${config.API}/operations/${id}/closeReport`
        const options = {
          method: 'POST',
          params: { jwt_token },
          url,
        }
        axios(options)
          .then(({ data: newOperation }) => {
            // TODO set only one operation
            commit('setOperation', newOperation)
            resolve(newOperation)
          })
          .catch(err => {
            reject(err.response.data.error)
          })
      } else {
        const err = 'not ready to be closed just yet'
        reject(err)
      }
    })
  },

  saveGeolocation({ commit }, { pos_latitude, pos_longitude, pos_date, id }) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('railsToken')

      const url = `${config.RAILS_API}/api/v1/app/operations/${id}/save_geolocation`
      const options = {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        url,

        data: qs.stringify({
          pos_latitude,
          pos_longitude,
          pos_date,
        })
      }

      axios(options)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const getters = {
  getEventsByYearMonth: state => pickerDateVal =>
    state.operations
      .map(order => {
        const date = format(order.operationDate, 'YYYY-MM-DD')
        const monthChosen = pickerDateVal.split('-')[1]
        const orderMonth = date.split('-')[1]
        if (monthChosen === orderMonth) {
          return date
        }
      })
      .filter(Boolean),
  filterOperationsByDate: state => date =>
    state.operations
      .filter(item => format(item.operationDate, 'YYYY-MM-DD') === date)
      .sort((a, b) => {
        if (a.operationDate < b.operationDate) return 1
        if (a.operationDate > b.operationDate) return -1
        return 0
      })
      .reverse(),
  unfinishedOperationsToday: state =>
    state.operations.filter(
      ({ operationDate, status }) =>
        isToday(operationDate) && status !== 'FINISHED'
    ),
}

export default {
  state,
  mutations,
  actions,
  getters,
}
