<template>
  <basicLayout>
    <!-- <v-snackbar v-model="snackbar" top :timeout="8000">
      Klicken Sie hier auf die Leiste für Feedback und Impressum!
    </v-snackbar> -->
    <v-container>
      <h2 style="text-align: center; margin-top: 20px">Bentomax GmbH</h2>
      <h3 style="text-align: center; margin-top: 25px">
        Teichkoppel 32<br />
        24229 Dänischenhagen
      </h3>
      <h3 style="text-align: center; margin-top: 15px; margin-bottom: 10px">
        {{ $t('views.home.phone') }}<br />
        {{ $t('views.home.fax') }}<br />
        M: info@bentomax.de
      </h3>
      <v-divider />
      <v-btn
        style="margin-top:1rem;height:4rem"
        block
        color="primary"
        @click="bug_modal = true"
      >
        {{ $t('views.home.feedback_errors') }}<br />{{ $t('views.home.for_developers') }}
      </v-btn>
      <v-dialog v-model="bug_modal" fullscreen>
        <v-card v-if="bug_modal">
          <v-card-title class="headline grey lighten-3" primary-title>
            {{ $t('views.home.report_error') }}
          </v-card-title>
          <v-card-text style="padding:0 0.5rem;margin:0;margin-top:1rem">
            <v-text-field
              v-model="bug_title"
              style="margin:0;padding:0"
              prepend-icon="fa-tag"
              :label="$t('views.home.description')"
              :hint="$t('views.home.brief_description')"
              single-line
              :rules="[rules.max]"
            />
            <v-textarea
              v-model="bug_descr"
              style="margin:0;padding:0"
              prepend-icon="fa-question-circle"
              :label="$t('views.home.description')"
              no-resize
              :hint="$t('views.home.what_happened')"
            />
            <v-textarea
              v-model="bug_how"
              style="margin:0;padding:0"
              prepend-icon="fa-binoculars"
              :label="$t('views.home.ways_to_reproduce')"
              rows="3"
              no-resize
              :hint="$t('views.home.steps')"
            />
            <v-radio-group
              v-model="bug_prio"
              style="margin:0;padding:0;height:5rem"
              column
            >
              <v-layout>
                <v-icon style="margin-right:1rem">
                  fa-exclamation-circle
                </v-icon>
                <v-flex v-for="n in 5" :key="n">
                  {{ n }}
                  <v-radio :key="n" :value="n" style="margin:0" />
                </v-flex>
              </v-layout>
              <v-layout>
                <p style="margin-left:2rem">{{$t('views.home.later')}}</p>
                <v-spacer />
                <p>{{$t('views.home.important')}}</p>
              </v-layout>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="bug_modal = false">
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn flat color="primary" @click="send_email('bug_report')">
              {{$t('views.home.send')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        block
        color="primary"
        style="height:4rem"
        @click="
          getUpdates()
          update_modal = true
        "
      >
        Changelog
      </v-btn>
      <v-dialog v-model="update_modal" fullscreen>
        <v-card v-if="update_modal" @click="update_modal = false">
          <v-card-title class="headline grey lighten-3" primary-title>
            Changelog
          </v-card-title>
          <v-card-text id="updates" style="overflow-y:auto" />
        </v-card>
      </v-dialog>
      <elmApp />
    </v-container>
  </basicLayout>
</template>

<script>
import basicLayout from '@/layouts/basicLayout'
import { mapGetters } from 'vuex'
import {ElmComponent, app} from '@/elm/elmComponent'

export default {
  components: {
    basicLayout,
    elmApp: ElmComponent(),
  },
  data() {
    return {
      snackbar: true,
      update_modal: false,
      bug_modal: false,
      bug_title: '',
      bug_descr: '',
      bug_prio: '',
      bug_how: '',
      rules: {
        max: v => v.length <= 20 || 'Max. 20 Zeichen',
      },
      router: this.$router,
      currentRoute: this.$route,
    }
  },
  computed: {
    ...mapGetters(['unfinishedOperationsToday', 'deliveryCount']),
  },
  mounted() {
    // const operationsToday = this.$store.state.operations.filter(
    //   item =>
    //     format(item.operationDate, 'YYYY-MM-DD') ===
    //     format(Date.now(), 'YYYY-MM-DD')
    // )
    //
    // for (const operation of operationsToday) {
    //   if (operation.id) this.$store.dispatch('getOperation', operation.id)
    // }
    //
    // console.log("call getOperations: " + this.$store.state.operations.length);
    // if (this.$store.state.operations.length === 0) {
    console.log('call getOperations')
    this.$store.dispatch('getOperations')
    this.$store.dispatch('getProjects')
    // }
  },
  methods: {
    send_email(reason) {
      switch (reason) {
        case 'bug_report':
          var mail = "";

          if (this && this.$store && this.$store.state && this.$store.state.user && this.$store.state.user.email) {
            mail = this.$store.state.user;
          }

          Email.send({
            SecureToken: 'da376082-b227-42f9-a4d7-7722ac4f2a66',
            To: 'j.hoffmann@bentomax.de',
            From: 'toni_db@bentomax.de',
            Subject: 'Bug_Report: ' + this.bug_title,
            Body:
              this.bug_title +
              '<br>' +
              'Priorität: ' +
              this.bug_prio +
              '<br><br>' +
              'Beschreibung: ' +
              this.bug_descr +
              '<br>' +
              'Reproduktion: ' +
              this.bug_how +
              '<br>' +
              'Techniker Mail: ' +
              mail,
          })
          this.bug_modal = false
          this.bug_title = ''
          this.bug_descr = ''
          this.bug_prio = ''
          this.bug_how = ''
          return
      }
    },
    getUpdates() {
      var request = new XMLHttpRequest()
      request.open('GET', 'changelog.txt', true)
      request.send(null)
      request.onreadystatechange = function() {
        if (request.readyState === 4 && request.status === 200) {
          var type = request.getResponseHeader('Content-Type')
          if (type.indexOf('text') !== 1) {
            document.getElementById('updates').innerHTML = request.responseText
          }
        }
      }
    },
  },
}
</script>
