<template>
  <v-card>
    <v-card-title>
      <h3>{{$t('views.devices.upload_progress.not_all_photos_have_been_uploaded_yet')}}</h3>
    </v-card-title>
    <v-card-text>
      <p
        >{{$t('views.devices.upload_progress.upload_will_countinue_in_the_background')}}.</p
      >
      <p>~{{$tc('views.devices.upload_progress.n_more_files', uploadsInProgress, {n: uploadsInProgress})}}</p>
    </v-card-text>
    <v-btn @click="restartUploads()">
      {{$t('views.devices.upload_progress.retry_upload')}}
    </v-btn>
  </v-card>
</template>

<script>
import sync from '@/sync/sync'

export default {
  components: {},
  props: ['uploadsInProgress'],
  data() {
    return {}
  },
  computed: {
    active() {
      // FIXME: use vuex here to make this "world reactable"
      return true
    },
  },
  watch: {},
  mounted() {},
  methods: {
    restartUploads() {
      sync.start()
    },
  },
}
</script>

<style lang="scss"></style>
