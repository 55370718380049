<template>
  <basicLayout>
    <v-container>
      <h1>Komponenten</h1>
      <h2>HumanDate</h2>
      <div>Morgen: <HumanDate :date="tomorrow"/></div>
      <div>Heute: <HumanDate :date="today"/></div>
      <div>Gestern: <HumanDate :date="yesterday"/></div>
      <div>Vor einer Woche: <HumanDate :date="oneWeekAgo"/></div>
      <h2>SlaColorBlock</h2>
      <SlaColorBlock :slaTime="yesterday.getTime()" :colored="false" />
      <hr />
      <SlaColorBlock :slaTime="yesterday.getTime()" colored />
      <SlaColorBlock :slaTime="today.getTime()" colored />
      <SlaColorBlock :slaTime="tomorrow.getTime()" colored />
      <SlaColorBlock :slaTime="oneWeekAhead.getTime()" colored />
      <SlaColorBlock :slaTime="sixHoursAhead.getTime()" colored />
      <SlaColorBlock :slaTime="-1" colored />
    </v-container>
  </basicLayout>
</template>

<script>
import basicLayout from '../layouts/basicLayout'
import HumanDate from '../components/Order/HumanDate'
import SlaColorBlock from '../components/Order/SlaColorBlock'
import { startOfTomorrow, startOfYesterday, addDays, addHours } from 'date-fns'

export default {
  components: { basicLayout, HumanDate, SlaColorBlock },
  data: function() {
    return {
      today: new Date(),
      tomorrow: startOfTomorrow(),
      yesterday: startOfYesterday(),
      oneWeekAgo: addDays(new Date(), -7),
      oneWeekAhead: addDays(new Date(), 7),
      sixHoursAhead: addHours(new Date(), 6),
    }
  },
}
</script>
