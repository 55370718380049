<template>
  <div v-if="img || overlayedImg" class="camera-app selector">
    <h1>{{$t('views.devices.camera.use_photo')}}</h1>
    <div class="width-wrapper">
      <img :src="overlayedImg || img" class="img-responsive" />
    </div>
    <div class="controls">
      <v-btn color="error" @click="newPicture">{{$t('views.devices.camera.change_photo')}}</v-btn>
      <v-btn
        :disabled="!overlayedImg || !img"
        color="success"
        @click="sendPhotos"
      >
        {{$t('views.devices.camera.use')}}
      </v-btn>
    </div>
  </div>
  <div v-else class="camera-app">
    <div class="width-wrapper">
      <div class="video-container">
        <img :src="overlay" alt="" />
        <vue-web-cam
          ref="webcam"
          :device-id="deviceId"
          :resolution="{ height: 1500, width: 1500 }"
          width="100%"
          height="auto"
          screenshotFormat="image/jpeg"
          @started="onStarted"
          @stopped="onStopped"
          @error="onError"
          @cameras="onCameras"
          @camera-change="onCameraChange"
        />
      </div>
    </div>

    <div class="switch-camera">
      <select v-model="camera">
        <option>-- {{$t('views.devices.camera.select_camera')}} --</option>
        <option
          v-for="dev in devices"
          :key="dev.deviceId"
          :value="dev.deviceId"
          >{{ dev.label }}</option
        >
      </select>
      <i class="v-icon material-icons" style="color: white;">
        flip_camera_ios
      </i>
    </div>

    <button
      class="capture-photo"
      :disabled="!streamStarted"
      :class="capturing ? 'snapping' : ''"
      :style="!streamStarted ? 'opacity: 5%;' : ''"
      @click="onCapture"
      >{{$t('views.devices.camera.take_photo')}}</button
    >
  </div>
</template>

<script>
import { WebCam } from 'vue-web-cam'
import mergeImages from '../../lib/merge-images'

export default {
  components: {
    'vue-web-cam': WebCam,
  },
  data() {
    return {
      capturing: false,
      img: null,
      overlayedImg: null,
      camera: null,
      deviceId: null,
      devices: [],
      overlay_: this.overlay,
      overlayPng: null,
      imageSelected_: this.imageSelected,
      abortTakingImage_: this.abortTakingImage,
      streamStarted: false,
    }
  },
  props: ['overlay', 'imageSelected', 'abortTakingImage'],
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId)
    },
  },
  watch: {
    camera: function(id) {
      this.deviceId = id
    },
    devices: function() {
      // Once we have a list select the first one
      const [first] = this.devices
      if (first) {
        // If the camera was switched before, assume that the user wants to use that one in the future
        this.camera = this.$store.state.devices.selectedCamera || first.deviceId
        this.deviceId =
          this.$store.state.devices.selectedCamera || first.deviceId
      }
    },
  },
  mounted() {},
  methods: {
    onCapture() {
      const overlay = this.overlay_
      this.img = this.$refs.webcam.capture()
      this.capturing = true
      mergeImages([this.img, overlay]).then(b64 => (this.overlayedImg = b64))

      setTimeout(() => {
        this.capturing = false
      }, 150)
    },
    onStarted(stream) {
      console.log('On Started Event', stream)
      this.streamStarted = true
    },
    onStopped(stream) {
      console.log('On Stopped Event', stream)
      this.streamStarted = false
    },
    onStop() {
      this.$refs.webcam.stop()
    },
    onStart() {
      this.$refs.webcam.start()
    },
    onError(error) {
      console.log('On Error Event', error)
    },
    onCameras(cameras) {
      this.devices = cameras
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
      console.log('On Camera Change Event', deviceId)
      this.$store.commit('selectCamera', deviceId)
    },
    newPicture() {
      this.img = null
      this.overlayedImg = null
      this.devices = []
      this.camera = null
      this.deviceId = null
    },
    sendPhotos() {
      this.imageSelected_(this.img, this.overlayedImg)
    },
  },
}
</script>

<style lang="scss" scoped>
.width-wrapper {
  margin: auto;
  max-width: 100vh;
}

.selector {
  .width-wrapper {
    max-width: 80vh;
  }
  h1 {
    text-align: center;
    color: white;
  }
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: rgba(255, 255, 255, 0.1);
}
.video-container video,
.video-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.video-container img {
  z-index: 2;
}

img {
  max-width: 100%;
}

.ratio {
  background: green;
  opacity: 0.5;
  width: 100%;
  padding-bottom: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.button-group {
  display: flex;
  > * {
    width: 50%;
  }
}

.capture-photo {
  display: block;
  flex: 1 1 auto;
  background-color: rgba(255, 255, 255, 1);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid #434343;
  box-shadow: 0 0 0 3pt white;
  font-size: 0;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 0.05s ease-in;
  z-index: 10;

  &.snapping {
    background-color: rgba(255, 255, 255, 0);
  }

  &:focus {
    outline: 0;
  }
}

.switch-camera {
  position: absolute;
  display: flex;
  width: 45px;
  height: 45px;
  bottom: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  // overflow: hidden;

  > select {
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.camera-app {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  background: #434343;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.controls {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 15px;

  button {
    padding: 15px 25px;
    margin: 2px;
    background: white;
    font-family: 'Roboto', sans-serif;
  }

  .reset {
    border: 1px solid white;
    background: transparent;
    color: white;
  }
}
</style>
