<template>
  <v-toolbar dark dense color="primary">
    <img
      @click="home"
      style="width:32px;height:32px;margin-right:1rem"
      src="/img/icons/android-chrome-192x192.png"
    />
    <v-toolbar-title
      class="white--text"
      style="padding:0;margin:0"
      @click="home"
      >Bentomax</v-toolbar-title
    >
    <v-spacer></v-spacer>
    <geolocationChecker />
    <div>
      <select style="padding: 0px 8px; border: 2px solid #004fb1; border-radius: 6px; margin: 0px 6px" @change="switchLocale($event)">
        <option :selected="languageIsSelected(locale)" style="color: black" :value="locale" v-for="locale in locales" :key="locale">
          {{ locale }}
        </option>
      </select>
    </div>
    <img v-if="this.$i18n.locale == 'de'" style="height: 21px;" src="./../assets/de.svg" alt="">
    <img v-if="this.$i18n.locale == 'en'" style="height: 21px;" src="./../assets/gb.svg" alt="">
    <v-btn icon>
      <v-icon @click="logout">exit_to_app</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import {app} from '@/elm/elmComponent'
import geolocationChecker from '../components/geolocationChecker'

export default {
  components: {
    geolocationChecker,
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn
    },
  },
  methods: {
    logout: function() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
    home: function() {
      this.$router.push('/').catch(() => {})
    },
    switchLocale(event) {

      let locale = event.target.value
      console.log("Switching language to: " + locale)
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        localStorage.setItem('i18n_local_language', locale)

      if (
        app &&
        app.ports &&
        app.ports.in_
      ) {
          app.ports.in_.send({
            msg: 'JsSwitchLocale',
            data: locale,
          })
        }
      }

    },
    languageIsSelected: function(language) {
      return this.$i18n.locale == language
    }
  },
  data() {

    // set up stored language
    let local_language = localStorage.getItem('i18n_local_language')
    if (local_language) {
      this.$i18n.locale = local_language;
    } else {
      this.$i18n.locale = 'en';
    }

    let env_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE
    return {
      locales:  env_locales ? env_locales.split(',') : ['en', 'de']
    }

  }
}
</script>
