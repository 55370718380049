export const config = {
  isDevelopment: () => process.env.NODE_ENV !== 'production',
  isProduction: () => process.env.NODE_ENV === 'production',
  baseUrl: process.env.VUE_APP_API_SERVER,
  API: process.env.VUE_APP_API_SERVER + '/api',
  RAILS_API: process.env.VUE_APP_API_SERVER + '/r',
  version: process.env.VUE_APP_VERSION,
  featureFlags: {
    materialerfassung: {
      user: ['t.kruse@bentomax.de'],
      project: [105, 95, 120, 116, 123, 98, 110, 12],
    },
  },
}

export function isFeatureAllowed(compare, featureKey, flagKindKey) {
  const flags = config.featureFlags || {}
  const kind = flags[featureKey] || {}
  const authorizedValues = kind[flagKindKey] || []

  return authorizedValues.includes(compare)
}
