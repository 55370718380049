import SignaturePad from 'signature_pad'

//
// Check out src/Main.elm to see how you use this on the Elm side.
//
function resizeCanvas(signaturePad: SignaturePad, canvas: HTMLCanvasElement) {
  var ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  let ctx = canvas.getContext("2d")
  if (ctx) {
    ctx.scale(ratio, ratio);
  }
  signaturePad.clear(); // otherwise isEmpty() might return incorrect value
}

customElements.define('signature-pad',
  class extends HTMLElement {
    // things required by Custom Elements
    constructor() {
      super();
    }
    connectedCallback() {
      let wrapper = document.createElement("DIV")

      wrapper.style.width = "100%"
      wrapper.style.height = "100%"

      let canvas = document.createElement("CANVAS") as HTMLCanvasElement

      wrapper.appendChild(canvas)

      // let signaturePad = new SignaturePad(canvas, {onEnd: function() {
      //   const currentCanvasContent = this.toDataURL("image/svg+xml")

      // }});
      let signaturePad = new SignaturePad(canvas)
      signaturePad.onEnd = () => {
        const signature = signaturePad.toDataURL("image/svg+xml")

        this.dispatchEvent(new CustomEvent('signature-change', {
          detail: {
            signature
          }
        }))
      }

      this.appendChild(wrapper);

      resizeCanvas(signaturePad, canvas)
    }
    attributeChangedCallback() {}
    static get observedAttributes() { return []; }

    // Our function to set the textContent based on attributes.
    setTextContent() {
      // const width = this.getAttribute('width');
      // const height = this.getAttribute('height');
      //this.textContent = localizeDate(width, height);
      // if (width) { wrapper.style.width = width}
      // if (height) { wrapper.style.height = height}
    }
  }
);
