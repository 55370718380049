import axios from 'axios'
import qs from 'qs'
import { config } from '@/config'

function upload(signature) {
  const url = `${config.RAILS_API}/api/v1/app/operations/signature_upload.json`
  const token = localStorage.getItem('railsToken')
  const options = {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    data: qs.stringify(signature),
    url,
  }

  return axios(options)
}

export default upload
