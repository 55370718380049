<template>
  <div
    v-if="job.job_type === 'picture'"
    :key="job.id"
    class="checklist-element"
  >
    <div
      v-if="job.done || job.queued"
      :class="job.queued ? 'success-indicator progress' : 'success-indicator'"
    >
      <i class="v-icon material-icons" style="color: white;">
        check_circle
      </i>
    </div>

    <v-card-title>
      {{ job.description }}
    </v-card-title>
    <div class="checklist-control">
      <v-btn @click="openPhotoDocumentation(job.id)">{{$t('views.devices.job.take_photo')}}</v-btn>
    </div>
  </div>

  <div
    v-else-if="
      job.job_type === 'bool' ||
        job.job_type === 'checklist' ||
        job.job_type === 'bool_with_reason'
    "
    :key="job.id"
    class="checklist-element"
  >
    <div v-if="job.done" class="success-indicator">
      <i class="v-icon material-icons" style="color: white;">
        check_circle
      </i>
    </div>

    <v-card-title>
      {{ job.description }}
    </v-card-title>

    <div class="checklist-control">
      <v-btn
        :color="job.value === 'true' ? 'success' : ''"
        @click="
          job.job_type === 'bool_with_reason'
            ? openOverlay(job, true)
            : answerBool(job.id, true)
        "
      >
        {{$t('views.devices.job.yes')}}
      </v-btn>
      <v-btn
        :color="job.value === 'false' ? 'error' : ''"
        @click="openOverlay(job, false)"
      >
        {{$t('views.devices.job.no')}}
      </v-btn>
    </div>
  </div>

  <div v-else-if="job.job_type === 'checklist_info'" :key="job.id">
    <h3 class="card-heading">
      {{ job.description }}
    </h3>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['job', 'openOverlay', 'answerBool', 'openPhotoDocumentation'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
.card-heading {
  padding: 20px 10px;
}

.checklist-element {
  border: 1px solid #eee;
  margin: 10px 5px;
  border-radius: 5px;
  padding: 5px;
  position: relative;
}

.checklist-control {
  text-align: center;
}

.success-indicator {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  background: #1976d2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.success-indicator.progress {
  background: #ffb100;
}
</style>
