import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'

import * as Sentry from '@sentry/browser'
// import { Vue as VueIntegration } from '@sentry/integrations'

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './registerServiceWorker'
import { config } from './config'

import { app } from './elm/elmComponent'
import i18n from './i18n'

const vueSignature: any = require('vue-signature')

//
// Use the value (to appease the build tool). We need to do this to make sure
// the Elm app is initialized and not discarded by the garbage collector.
//
declare global {
  interface Window {
    app: any
    logoutHook: any
  }
}
window.app = app

//
// Service Worker refresh setup
//
let refreshing = false
navigator.serviceWorker.addEventListener('controllerchange', () => {
  // Prevent multiple reloads
  if (refreshing) return
  refreshing = true
  // Here the actual reload of the page occurs
  window.location.reload()
})

// if (config.isProduction()) {
//   // Prevents spamming the sentry console with botched dev builds
//   Sentry.init({
//     dsn:
//       'https://45e7bba9f9f2458f97779f09b6dcec6b@o422866.ingest.sentry.io/5352342',
//     integrations: [new VueIntegration({ Vue, attachProps: true })],
//     release: config.version,
//   })
// }

Vue.config.productionTip = false

window.logoutHook = function() {
  store.dispatch('logout').then(() => {
    router.push('/login')
  })
}

// Vue.use({
//   iconfont: 'fa',
// })

Vue.use(vueSignature)

Vue.prototype.$http = Axios
const token = localStorage.getItem('token')
if (token === 'undefined') {
  localStorage.removeItem('token')
}
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

console.log(config.version)
