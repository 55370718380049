// --------------------------------
// see: https://developers.google.com/maps/documentation/urls/guide#top_of_page
// --------------------------------

/**
 * Open a new window with Google Maps and search for the given address
 * @param addr
 */
function openSearch(addr) {
  openUrl('https://www.google.com/maps/search/?api=1&query=', addr)
}

/**
 * Open a new window with Google Maps and show the direction for the given address
 * @param addr
 */
function openDirection(addr) {
  openUrl('https://www.google.com/maps/dir/?api=1&destination=', addr)
}

function openUrl(url, addr) {
  if (addr === undefined) {
    alert('Adresse ist leer')
    return
  }
  window.open(url + getSearchQuery(addr))
}

function getSearchQuery(addr) {
  const addrQuery = [addr.street, addr.streetNo, addr.city, addr.zip]
  return addrQuery.join('%2C+')
}

export { openSearch, openDirection }
